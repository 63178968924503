import { uuid4Regexp } from '@schemas/regexps/uuid4';
import { type Primitive } from '@tsUtils';

type Tags = 'microservice' | 'endpoint' | 'apiVersion';

type UrlTags = Partial<Record<Tags, Primitive>>;

/**
 * утилита для разбиения url на теги
 */
export const parseUrlToTags = (url = ''): UrlTags => {
  const apiPath = `${globalThis.location.origin}/api/`;
  const isApi = Boolean(url.match(apiPath));

  if (!isApi) {
    return {};
  }

  // ожидается, что весь урл состоит из вида https://хост.домен/api/версия апи/название микросервиса/оставшаяся часть, которая может содержать гуид
  const parts = url.replace(apiPath, '').split('/');

  const endpoint = parts
    // отсекаем часть с версией api и микросервисом
    .slice(2)
    // заменяем гуид видом [id], это позволит сопоставлять одинаковые ошибки
    .map((part) => (part.match(uuid4Regexp) ? '[id]' : part))
    // и сохраняем оставшуюся часть
    .join('/');

  return {
    apiVersion: parts[0],
    microservice: parts[1],
    endpoint,
  };
};
