import { FORM_ERROR_MESSAGE } from '@constants/form/errorText';

import { optional, string } from '../schema/schema';
import { isPhone } from '../validators/isPhone';

//TODO: рефакторинг KADRY-5569
export const createPhoneField = (required = true) => {
  const baseTestFiledValidation = string((value, ctx) => {
    if (!isPhone(value, required)) {
      return ctx.createError({
        message: FORM_ERROR_MESSAGE.PHONE,
        code: 'phone',
      });
    }

    return undefined;
  }).define({ requiredErrorMessage: FORM_ERROR_MESSAGE.REQUIRED });

  return required ? baseTestFiledValidation : optional(baseTestFiledValidation);
};
