import { MicroserviceRepository } from 'common/data';
import { type GetBaseUrlQuery } from 'common/data/api-core';

import {
  type ApiError,
  type CacheService,
  cacheService,
} from '@dashboard/shared';

import { type DashboardConfig, configRepository } from '../ConfigRepository';
import { IdentityClient } from '../../sources/client/identity';

import { userLoginByUserIdKey } from './constants';
import { type GetUserLoginByUserIdQueryParams } from './types';

type IdentityRepositoryParams = {
  cacheService: CacheService<ApiError>;
  getConfigQuery: GetBaseUrlQuery<DashboardConfig>;
};

export class IdentityRepository extends MicroserviceRepository<
  IdentityClient,
  DashboardConfig
> {
  private params: IdentityRepositoryParams;

  constructor(params: IdentityRepositoryParams) {
    super({
      getConfigQuery: params.getConfigQuery,
      microserviceClient: IdentityClient,
    });

    this.params = params;
  }

  public getRefreshQuery = () =>
    this.params.cacheService.createMutation(
      ({ refreshToken }: { refreshToken?: string }) =>
        this.client.esa.esaRefreshToken({
          refreshToken,
        }),
    );

  public getLoginQuery = () =>
    this.params.cacheService.createMutation(
      ({ password, login }: { login: string; password: string }) =>
        this.client.esa.esaLogin({
          requestBody: {
            Login: login,
            PasswordHash: password,
          },
        }),
    );

  public getUserLoginByUserIdQuery = (
    params: GetUserLoginByUserIdQueryParams,
  ) =>
    this.params.cacheService.createQuery(
      [userLoginByUserIdKey(params.userId)],
      () => this.client.users.usersGetLogin({ userId: params.userId }),
      { enabledAutoFetch: false },
    );
}

export const identityRepository = new IdentityRepository({
  getConfigQuery: configRepository.configQuery,
  cacheService,
});
