import { styled } from '../../../styled';
import { ActionButton } from '../../../ActionButton';

export const Inner = styled.div`
  overflow-y: auto;

  width: 100%;
  min-width: 320px;
  max-width: 100vw;
  height: 70vh;
  max-height: 70vh;

  @supports (height: 100dvh) {
    height: 70dvh;
    max-height: 70dvh;
  }
  padding: ${({ theme }) => theme.spacing(4, 3)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: max-content;
    max-width: min(320px, 25vw);
    max-height: 100%;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: min(420px, 33vw);
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding: ${({ theme }) => theme.spacing(6, 4)};
  }
`;

export const Container = styled.section`
  ${({ theme }) => theme.breakpoints.down('md')} {
    position: fixed;
    top: 0;
    right: 0;
  }

  position: relative;
  z-index: 2;
  height: 100%;
  max-height: 100%;
`;

export const ToggleButton = styled(ActionButton)`
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;
