import { type ReactNode, Suspense, useEffect, useId, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useResizeObserver } from '@hooks/useResizeObserver';
import { useStore } from '@hooks/useStore';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import Warn from '../Icons/Warn.svg';
import { Placeholder } from '../Placeholder';
import { ContentViewer } from '../ContentViewer';
import { TablePagination } from '../Table/Pagination';
import { ContentLoader } from '../ContentLoader';
import { PersonalData } from '../PersonalData';

import { PDFPage } from './PdfPage';
import {
  PDFDocumentGrid,
  PDFDocumentStyled,
  PageWrapper,
  TagWrapper,
} from './styles';
import { createPdfReaderStore } from './store';
import { type PdfReaderVariant } from './types';

export type PDFTextItem = {
  pageIndex: number;
  pageNumber: number;
  itemIndex: number;
  str: string;
  dir: string;
  transform: Array<unknown>;
  width: number;
  height: number;
  fontName: string;
  hasEOL: boolean;
};

type PageProps = {
  customTextRenderer?: (item: PDFTextItem) => string;
  className?: string;
};

export type PdfReaderProps = {
  errorText?: string;
  isLoading?: boolean;
  pageProps?: PageProps;
  variant?: PdfReaderVariant;
  file?: File | Blob | null;
  pageWidth?: number;
};

type PageElementProps = {
  length: number;
  pageNumber: number;
  scale: number;
  pageProps?: PageProps;
  loading?: ReactNode;
  observeNode: (node: HTMLDivElement, scrollRoot: HTMLDivElement) => void;
  unobseveNode: (node: HTMLDivElement) => void;
  scrollRoot?: HTMLDivElement | null;
  shouldObserve?: boolean;
  pageWidth?: number;
};

const Loader = () => (
  <ContentLoader title="Загружаем документ" size="xm" type="pale" />
);

const PageElement = ({
  pageNumber,
  length,
  pageProps,
  loading,
  observeNode,
  unobseveNode,
  scrollRoot,
  shouldObserve = true,
  scale,
  pageWidth,
}: PageElementProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!shouldObserve || !ref.current || !scrollRoot) {
      return;
    }

    const node = ref.current;

    observeNode(node, scrollRoot);

    return () => {
      unobseveNode(node);
    };
  }, [ref, shouldObserve]);

  return (
    <PageWrapper ref={ref} className="pageItem" data-pagenumber={pageNumber}>
      {length > 1 && (
        <TagWrapper
          color="grey"
          label={`ст. ${pageNumber}`}
          rounded
          skipFocusWhenDisabled={undefined}
        />
      )}
      <PersonalData>
        <PDFPage
          pageNumber={pageNumber}
          loading={loading}
          scale={scale}
          width={pageWidth}
          {...pageProps}
        />
      </PersonalData>
    </PageWrapper>
  );
};

const PdfReader = observer(
  ({
    errorText = 'Не удалось загрузить документ',
    isLoading = false,
    pageProps = {},
    variant = 'default',
    ...props
  }: PdfReaderProps) => {
    const id = useId();
    const scrollRef = useRef(null);
    const rootRef = useRef(globalThis?.document.documentElement);
    const store = useStore(() => createPdfReaderStore({ errorText }));

    useResizeObserver({ ref: rootRef, sizeCb: store.onResize });

    useEffect(() => {
      return () => {
        // указываем, что при анмаунте компонента, нужно вызвать очистку памяти
        store.destroy();
      };
    }, []);

    return (
      <ContentViewer
        isLoading={isLoading}
        loadingContent={<Loader />}
        isError={store.isError || (!isLoading && !props.file)}
        errorContent={<Placeholder title={store.error} imgSrc={Warn} />}
      >
        <Suspense fallback={<Loader />}>
          {!isLoading && (
            <PDFDocumentGrid className="pdfReader__grid" variant={variant}>
              <PDFDocumentStyled
                variant={variant}
                onLoadSuccess={store.onDocumentLoadSuccess}
                onLoadError={store.onError}
                onSourceError={store.onError}
                noData={store.error}
                inputRef={scrollRef}
                loading={<Loader />}
                {...props}
              >
                {store.pages.map((index) => (
                  <PageElement
                    shouldObserve={variant === 'default'}
                    key={`${id}_${index}`}
                    length={store.numPages}
                    pageNumber={index + 1}
                    pageProps={pageProps}
                    loading={<Loader />}
                    observeNode={store.observeNode}
                    unobseveNode={store.unobseverNode}
                    scrollRoot={scrollRef.current}
                    scale={store.scale}
                    pageWidth={props.pageWidth}
                  />
                ))}
              </PDFDocumentStyled>

              {variant === 'default' && (
                <TablePagination
                  loading={isLoading && store.isLoading}
                  count={store.numPages}
                  page={store.page}
                  onChangePage={(number) =>
                    store.onPageChange(number, scrollRef.current)
                  }
                />
              )}
            </PDFDocumentGrid>
          )}
        </Suspense>
      </ContentViewer>
    );
  },
);

PdfReader.displayName = 'PdfReader';

export { PdfReader };
