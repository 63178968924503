import { TransactionOutbox } from 'common';

export type TransactionEvent =
  | 'serviceTermsAccepted'
  | 'userWithAcceptedTermsLoaded'
  | 'newUserCreated'
  | 'multiSignSuccessSomeDocuments'
  | 'issueUUES';

export const transactionOutbox = new TransactionOutbox<TransactionEvent>();
