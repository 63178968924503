import { type Certificate } from '@astral/cryptopro-cades';

export enum CertificateType {
  Individual = '\u0424\u041B',
  SoleTrader = '\u0418\u041F',
  LegalEntity = '\u042E\u041B',
}

type CertificateShortInfo = {
  notAfter: string | null;
  subjectKeyId: string | null;
  name: string | null;
  ownerName: string | null;
  inn: string | null;
  type: CertificateType;
};

const getCertificateExpiresDate = (date: Date | null) => {
  if (!date) {
    return null;
  }

  return new Date(date).toLocaleDateString();
};

export const transformCertificate = (
  certificate: Certificate,
): CertificateShortInfo => {
  const сertificateTypeIfNotLegal = certificate.subject.ogrnip
    ? CertificateType.SoleTrader
    : CertificateType.Individual;

  const isCertificateTypeLegal = certificate.subject.innLe;

  return {
    notAfter: getCertificateExpiresDate(certificate.notAfter),
    subjectKeyId: certificate.subjectKeyId,
    name: certificate.subject.commonName,
    ownerName: `${certificate.subject.surname} ${certificate.subject.name}`,
    inn: certificate.subject.innLe || certificate.subject.inn,
    type: isCertificateTypeLegal
      ? CertificateType.LegalEntity
      : сertificateTypeIfNotLegal,
  };
};
