import { LazyDestroyer } from '@services/LazyDestroyer';
import { generateBlobUrl } from '@services/file/utils/generateBlobUrl';

type FileImageViewerStoreParams = {
  file: File;
};

/**
 * стор предназначенный для работы компонента с предпросмотром картинки из блоба
 */
export class FileImageViewerStore {
  private destroyer = new LazyDestroyer();

  private urlObject: ReturnType<typeof generateBlobUrl>;

  constructor(params: FileImageViewerStoreParams) {
    this.urlObject = generateBlobUrl(params.file);
  }

  /**
   * метод инициализации, предполагается использование в useEffect,
   * при анмаунте вызовется дестрой, что приведет к уничтожению ссылки на блоб
   */
  public init = () => {
    this.destroyer.push(this.urlObject.revoke);
    this.destroyer.init();

    return this.destroy;
  };

  public get blobUrl() {
    return this.urlObject.url;
  }

  private destroy = () => {
    this.destroyer.destroy();
  };
}

export const createFileImageViewerStore = (file: File) =>
  new FileImageViewerStore({ file });
