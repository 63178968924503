import { forwardRef, useEffect, useState } from 'react';
import { Checkbox, ListItemIcon } from '@astral/ui';
import { classnames } from '@utils/string/classnames';

import { OverflowTypography } from '../OverflowTypography';
import { MenuItem } from '../MenuItem';
import { METRIKA_CLASSES } from '../../constants/metrikaClasses';

import { SelectWrapper } from './styles';
import { type OptionItem, type SelectProps } from './types';
import { getSelectedDefaultValue } from './utils';

export const Select = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      label,
      options,
      defaultValue,
      valueKey = 'id',
      titleKey = 'name',
      loading = false,
      multiple = false,
      value: innerValue,
      onChange,
      disabledOptions,
      hidePersonalData,
      readonly,
      ...props
    },
    ref,
  ) => {
    const setOptionDisabled = (it: OptionItem) =>
      disabledOptions ? disabledOptions(it) : false;

    const [selectedValue, setSelectedValue] = useState<
      string | boolean | (string | object)[]
    >(getSelectedDefaultValue(multiple, defaultValue || innerValue));

    useEffect(() => {
      setSelectedValue(
        getSelectedDefaultValue(multiple, defaultValue || innerValue),
      );
    }, [defaultValue, innerValue]);

    /**
     * @todo убрать es-lint disabled
     * */
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const handleChange = (event: any) => {
      setSelectedValue(event.target.value);

      if (onChange) {
        onChange(event);
      }
    };

    /**
     * @todo убрать es-lint disabled
     * */
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    type OptionArg = Record<string, any>;

    const renderOptions = options.map((option: OptionArg, index: number) => {
      let resultItemValue: object | string =
        typeof valueKey === 'object' ? {} : '';

      resultItemValue =
        typeof valueKey === 'object'
          ? valueKey.forEach((key: string | number) => {
              Object.assign(resultItemValue, { [key]: option[key] });
            })
          : option[valueKey];

      let resultItemTitle = '';

      resultItemTitle =
        typeof titleKey === 'object'
          ? titleKey.map(
              (optionKey: string | number) =>
                resultItemTitle + ' ' + option[optionKey],
            )
          : option[titleKey];

      const checked =
        multiple &&
        typeof selectedValue === 'object' &&
        selectedValue.includes(resultItemValue);

      return (
        <MenuItem
          key={index}
          value={resultItemValue as string}
          disabled={setOptionDisabled(option)}
          title={resultItemTitle}
        >
          {multiple && (
            <ListItemIcon>
              <Checkbox checked={checked} />
            </ListItemIcon>
          )}
          <OverflowTypography
            rowsCount={2}
            className={classnames({
              [METRIKA_CLASSES.blurContent]: hidePersonalData,
            })}
          >
            {resultItemTitle}
          </OverflowTypography>
        </MenuItem>
      );
    });

    const getOptionLabel = (value: string | number) => {
      const currentOption = options.find(
        (option) => option[valueKey as string] === value,
      );

      let optionLabel = '';

      optionLabel = (
        typeof titleKey === 'object' && currentOption
          ? titleKey.map(
              (optionKey: string | number) =>
                optionLabel + ' ' + currentOption[optionKey],
            )
          : currentOption &&
            typeof titleKey === 'string' &&
            currentOption[titleKey]
      ) as string;

      return (currentOption && optionLabel) || value;
    };

    const hasValue = Boolean(selectedValue?.toString());

    return (
      <SelectWrapper
        {...props}
        data-test={props.name}
        inputRef={ref}
        label={label || ''}
        inputProps={{
          className: classnames({
            [METRIKA_CLASSES.hideInputValue]: hidePersonalData,
          }),
          disabled: readonly,
          //TODO: обновить UIKIT и убрать, когда закроется задача UIKIT-1741
          autocomplete: 'off',
        }}
        loading={loading}
        multiple={multiple}
        defaultValue={defaultValue}
        value={selectedValue ?? null}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        hasValue={hasValue}
      >
        {renderOptions}
      </SelectWrapper>
    );
  },
);
