import { type PropsWithChildren, memo } from 'react';

import { type PopperProps } from '../Popper';
import { WarningIcon } from '../Icons';
import { ContentViewer } from '../ContentViewer';

import { AutocompletePopperInner, AutocompletePopperWrapper } from './styles';

type AutocompletePopperProps = Omit<PopperProps, 'children'> & {
  width: number;
  isLoading?: boolean;
};

export const AutocompletePopper = memo(
  ({
    children,
    width,
    isLoading,
    ...props
  }: PropsWithChildren<AutocompletePopperProps>) => (
    <AutocompletePopperWrapper
      placement="bottom-start"
      style={{ width: `${width}px` }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, -8],
          },
        },
      ]}
      popperOptions={{
        strategy: 'fixed',
      }}
      {...props}
    >
      <AutocompletePopperInner>
        <ContentViewer
          isLoading={Boolean(isLoading)}
          noData={
            <>
              <WarningIcon height={24} width={24} />
              Нет данных
            </>
          }
        >
          {children}
        </ContentViewer>
      </AutocompletePopperInner>
    </AutocompletePopperWrapper>
  ),
);
