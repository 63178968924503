import { Skeleton } from '@mui/material';

import { styled } from '../styled';

type SkeletonWrapperProps = {
  isFullWidth?: boolean;
};

export const SkeletonWrapper = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'isFullWidth',
})<SkeletonWrapperProps>`
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '')};
`;
