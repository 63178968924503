import {
  type HTMLAttributes,
  type ReactNode,
  createContext,
  useRef,
} from 'react';

import { ScrollableContainer } from './styles';

type ScrollLastOpenedToTopProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode | ReactNode[];
};

type ScrollableOptions = {
  scrollableElement?: HTMLElement | null;
};

export const ScrollableContext = createContext<ScrollableOptions>({
  scrollableElement: undefined,
});

export const ScrollableRoot = ({
  children,
  ...props
}: ScrollLastOpenedToTopProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <ScrollableContext.Provider value={{ scrollableElement: ref?.current }}>
      <ScrollableContainer ref={ref} {...props}>
        {children}
      </ScrollableContainer>
    </ScrollableContext.Provider>
  );
};
