import { styled } from '@common/shared/components';

export const ListWrapper = styled('ul')`
  list-style: none;
  margin: 0;
  padding: ${({ theme }) => theme.spacing(0, 6)};

  &:not(:empty) {
    padding: ${({ theme }) => theme.spacing(2, 6)};
  }
`;

export const ListItem = styled('li')`
  & + & {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;
