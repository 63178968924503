import { type PropsWithChildren, type ReactElement, useCallback } from 'react';
import { useControllableBackdropStack } from '@hooks/useControllableBackdropStack';

import { type DialogProps } from '../Dialog';
import { DialogContent } from '../DialogContent';
import { ErrorBoundary } from '../ErrorBoundary';

import {
  AlertModalDialogFooter,
  type AlertModalFooterProps,
} from './AlertModalDialogFooter';
import { DialogWrapper, FormWrapper } from './styles';

export type AlertModalProps = Omit<DialogProps, 'onClose'> &
  AlertModalFooterProps & {
    renderFooter?: (props: { footer: ReactElement }) => JSX.Element;
    onSubmit?: () => void;
    onClose?: () => void;
  } & {
    unmountOnExit?: boolean;
    onExited?: () => void;
    onEntered?: () => void;
  };

const WithForm = ({
  children,
  onSubmit,
}: PropsWithChildren<{ onSubmit?: () => void }>) => {
  if (onSubmit) {
    return (
      <FormWrapper
        onSubmit={onSubmit}
        data-test="AlertModalForm"
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </FormWrapper>
    );
  }

  return children as JSX.Element;
};

export const AlertModal = (props: PropsWithChildren<AlertModalProps>) => {
  const {
    children,
    maxWidth = 'xs',
    confirmButtonProps,
    cancelButtonProps,
    footerTemplate,
    renderFooter: RenderFooter,
    onSubmit,
    onClose,
    unmountOnExit,
    onExited,
    ...restProps
  } = props;

  const { handleClose } = useControllableBackdropStack({
    isOpened: restProps.open,
    onClose,
  });

  const DialogFooter = useCallback(() => {
    const footer = (
      <AlertModalDialogFooter
        footerTemplate={footerTemplate}
        confirmButtonProps={confirmButtonProps}
        cancelButtonProps={cancelButtonProps}
      />
    );

    if (RenderFooter) {
      return <RenderFooter footer={footer} />;
    }

    return footer;
  }, [RenderFooter, cancelButtonProps, confirmButtonProps, footerTemplate]);

  return (
    <DialogWrapper
      {...restProps}
      TransitionProps={{
        unmountOnExit,
        onExited: () => onExited?.(),
        onEntered: restProps.onEntered,
        ...restProps.TransitionProps,
      }}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={restProps.fullWidth ?? true}
      $hasForm={Boolean(onSubmit)}
    >
      <WithForm onSubmit={onSubmit}>
        <DialogContent>
          <ErrorBoundary>{children}</ErrorBoundary>
        </DialogContent>
        <DialogFooter />
      </WithForm>
    </DialogWrapper>
  );
};
