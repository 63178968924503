import { styled } from '@common/shared/components';

export const SectionLoader = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.modal + 1};

  width: 100%;
  height: 100%;
`;

export const SectionLoaderBackground = styled.div`
  width: 100%;
  height: 100%;

  opacity: 0.5;
  background-color: ${({ theme }) => theme.palette.grey[300]};
`;

export const SectionLoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%);
`;
