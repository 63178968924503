import { IconButton, TextField, type TextFieldProps } from '@astral/ui';
import {
  type FieldValues,
  type RegisterOptions,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { type NotificationService } from '@services/NotificationService';

import { InputAdornment } from '../../InputAdornment';
import { DocumentsOutlineIcon } from '../../Icons';
import { createClipboardStore } from '../../../stores';

type FormInputWithCopyProps<FormValues extends FieldValues> = {
  rules?: RegisterOptions;
  validate?: RegisterOptions['validate'];
  notify: NotificationService;
} & Omit<TextFieldProps, 'name'> &
  Omit<UseControllerProps<FormValues>, 'rules'>;

export const FormInputWithCopy = observer(
  <FormValues extends FieldValues>({
    notify,
    ...props
  }: FormInputWithCopyProps<FormValues>) => {
    const { field, fieldState } = useController(props);
    const errorMessages = fieldState.error?.message;
    const hasError = Boolean(errorMessages);

    const copyStore = useLocalObservable(createClipboardStore);

    const copyDocumentLink = () => {
      copyStore.copy(field.value).then(() => {
        notify.info('Текст скопирован в буфер обмена');
      });
    };

    return (
      <TextField
        {...field}
        {...props}
        error={hasError}
        helperText={hasError && errorMessages}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={copyDocumentLink} variant="text">
                <DocumentsOutlineIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  },
);
