import { styled } from '@common/shared/components';

export const MenuForm = styled.section`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-width: 400px;
    max-width: 400px;
  }

  & > form {
    padding: ${({ theme }) => theme.spacing(4)};
  }
`;
