import { type AxiosError } from 'axios';
import { logoutIfUnauthorized } from 'common';

export const logoutUnauthorizedInterceptor = (
  error: AxiosError,
): Promise<AxiosError | undefined> => {
  logoutIfUnauthorized(error);

  return Promise.reject(error);
};
