import type { GetBaseUrlQuery } from '@common/data/api-core';
import { MicroserviceRepository } from '@common/data';
import { IdentityClient } from '@api/client/identity';
import { type ApiError, type CacheService, cacheService } from '@shared';

import { type DashboardConfig, configRepository } from '../ConfigRepository';

import { type IntegrationTokensRepositoryDTO } from './dto';
import { tokensListKey } from './constants';

type IntegrationTokensRepositoryParams = {
  cacheService: CacheService<ApiError>;
  getConfigQuery: GetBaseUrlQuery<DashboardConfig>;
};

export class IntegrationTokensRepository extends MicroserviceRepository<
  IdentityClient,
  DashboardConfig
> {
  constructor(private readonly params: IntegrationTokensRepositoryParams) {
    super({
      getConfigQuery: params.getConfigQuery,
      microserviceClient: IdentityClient,
    });
  }

  public getListQuery = (
    params: IntegrationTokensRepositoryDTO.GetListQueryParams,
  ) =>
    this.params.cacheService.createInfiniteQuery(
      [tokensListKey(params.tenantId)],
      (): Promise<IntegrationTokensRepositoryDTO.ListItem[]> =>
        this.client.tenantTokens.tenantTokensGetTenantTokens({
          tenantId: params.tenantId,
        }),
      { isBackground: true },
    );

  public getRevokeQuery = () =>
    this.params.cacheService.createMutation(
      (
        params: IntegrationTokensRepositoryDTO.RevokeQueryExecutorParams,
      ): Promise<unknown> =>
        this.client.tenantTokens
          .tenantTokensRevokeTenantToken({
            tenantTokenId: params.id,
          })
          .then((res) => {
            this.params.cacheService.invalidate([
              tokensListKey(params.tenantId),
            ]);

            return res;
          }),
    );

  public getAddQuery = () =>
    this.params.cacheService.createMutation(
      (
        params: IntegrationTokensRepositoryDTO.AddQueryExecutorParams,
      ): Promise<string> =>
        this.client.tenantTokens
          .tenantTokensAddTenantToken({
            requestBody: {
              TenantId: params.tenantId,
              WorkEnded: params.expiration,
            },
          })
          .then((res) => {
            this.params.cacheService.invalidate([
              tokensListKey(params.tenantId),
            ]);

            return res.TokenValue;
          }),
    );
}

export const integrationTokensRepository = new IntegrationTokensRepository({
  getConfigQuery: configRepository.configQuery,
  cacheService,
});
