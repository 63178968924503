import { createPortal } from 'react-dom';
import { useUnit } from 'effector-react';

import { headStore } from '../../store';

export const HeadContainer = (): JSX.Element => {
  const { description, subTitles } = useUnit(headStore);

  return createPortal(
    <>
      {subTitles.length ? (
        <title>{subTitles.map(({ title }) => title).join(' | ')}</title>
      ) : null}
      {description ? <meta name="description" content={description} /> : null}
    </>,
    globalThis.document?.head,
  );
};
