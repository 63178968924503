import {
  Autocomplete as AstralAutocomplete,
  type AutocompleteProps as AstralAutocompleteProps,
} from '@astral/ui';

export type AutocompleteProps<
  AutocompleteValueProps,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean,
> = AstralAutocompleteProps<
  AutocompleteValueProps,
  Multiple,
  DisableClearable,
  FreeSolo
>;

export const Autocomplete = AstralAutocomplete;
