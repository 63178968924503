import { styled } from '../styled';
import { Popper } from '../Popper';

export const AutocompletePopperInner = styled.div`
  display: flex;

  width: 100%;
  max-width: 100%;

  background: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.small};
  box-shadow: ${({ theme }) => theme.elevation[200]};
`;

export const AutocompletePopperWrapper = styled(Popper)`
  z-index: ${({ theme }) => theme.zIndex.tooltip};

  width: 100%;
`;
