import { type Theme, styled } from '@common/shared/components';

import { type SeparateDirection } from './BlockSeparator';

const getDirectionProperty = (direction: SeparateDirection) => {
  const mapOfDirectionsProperty = {
    x: { width: '100%', height: '1px' },
    y: { width: '1px', height: '100%' },
  };

  return mapOfDirectionsProperty[direction];
};

const getDirectionSpacing = (direction: SeparateDirection, theme: Theme) => {
  const mapOfDirectionsProperty = {
    x: theme.spacing(4, 0),
    y: theme.spacing(0, 4),
  };

  return mapOfDirectionsProperty[direction];
};

export const StyledBlockSeparator = styled.div<{
  direction: SeparateDirection;
}>`
  ${({ direction }) => getDirectionProperty(direction)}

  margin: ${({ direction, theme }) => getDirectionSpacing(direction, theme)};

  background-color: ${({ theme }) => theme.palette.grey[300]};
`;
