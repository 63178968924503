/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentTypeDto } from '../models/DocumentTypeDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class DocumentTypesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Получение списка шаблонов
   * @returns DocumentTypeDto Коллекция шаблон
   * @throws ApiError
   */
  public documentTypesGetTemplates({
    tenantId,
    offset,
    count = 50,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Тенант шаблонов. **/
    tenantId?: string | null;
    /** Смещение. **/
    offset?: number;
    /** Количество элементов. **/
    count?: number;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<DocumentTypeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/templates/DocumentTypes',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        tenantId: tenantId,
        offset: offset,
        count: count,
      },
    });
  }
}
