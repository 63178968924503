import { type Primitive } from '@tsUtils';

import { type ConfigStore } from '../ConfigStore';

const MAX_TEXT_LENGTH = 30;

type ErrorTrackingOptions = {
  tags?: Record<string, Primitive>;
  extra?: Record<string, unknown>;
  contexts?: Record<string, Record<string, unknown>>;
};

type CaptureFn = (exception: unknown, options: ErrorTrackingOptions) => string;

const FB_FN = () => '';

type CaptureExceptionParams = {
  configStore: ConfigStore<{ SENTRY_DSN?: string; VERSION: string }>;
};

export class ErrorMonitoringService {
  private params: CaptureExceptionParams | null = null;

  private promise: null | Promise<CaptureFn> = null;

  private getSentry = () => import('@sentry/browser');

  private initService = () => {
    this.params?.configStore
      .async()
      .then(
        ({ VERSION, SENTRY_DSN }: { SENTRY_DSN?: string; VERSION: string }) => {
          if (!SENTRY_DSN) {
            return;
          }

          this.getSentry().then(({ init, BrowserTracing }) => {
            init({
              dsn: SENTRY_DSN,
              release: VERSION,
              dist: 'production',
              integrations: [new BrowserTracing()],
              tracesSampleRate: 1.0,
              beforeBreadcrumb(breadcrumb, hint) {
                if (breadcrumb?.message?.match(/yandex/)) {
                  return null;
                }

                if (breadcrumb?.category?.startsWith('ui')) {
                  const node = hint?.event.target;

                  let text =
                    node?.innerText ||
                    node?.ariaLabel ||
                    node?.placeholder ||
                    [...node?.labels]?.[0]?.innerText;

                  if (text.length > MAX_TEXT_LENGTH) {
                    text = `${text.substring(0, MAX_TEXT_LENGTH)}...`;
                  }

                  breadcrumb.message = `${node?.tagName.toLowerCase()}: ${text}`;
                }

                return breadcrumb;
              },
            });
          });
        },
      );
  };

  public init = (params: CaptureExceptionParams) => {
    this.params = params;
    this.initService();
  };

  public capture = (exception: unknown, options?: ErrorTrackingOptions) => {
    if (!this.promise && this.params) {
      this.promise = this.params.configStore
        .async()
        .then((data) =>
          data?.SENTRY_DSN
            ? this.getSentry().then(({ captureException }) => captureException)
            : FB_FN,
        )
        .catch((e) => {
          console.error(e);

          return FB_FN;
        });
    }

    this.promise?.then((capture) => capture(exception, options || {}));
  };
}

export const errorMonitoringService = new ErrorMonitoringService();
