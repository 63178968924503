import { type CacheService } from '@common/shared';

import { DEFAULT_CONFIG_PATH, configKey } from './constants';

type ConfigRepositoryParams = {
  cacheService: CacheService;
  url?: string;
};

export class ConfigRepository<TConfig> {
  private readonly params: ConfigRepositoryParams;

  constructor(params: ConfigRepositoryParams) {
    this.params = params;
  }

  public configQuery = <TConfigFallback = TConfig>() =>
    this.params.cacheService.createQuery<TConfigFallback>([configKey], () =>
      fetch(this.params.url || DEFAULT_CONFIG_PATH).then(
        (r) => r.json() as TConfigFallback,
      ),
    );
}
