export const MAP_OF_TEXT_LENGTH = {
  sx: 32,
  sm: 128,
  md: 255,
  md2: 256,
  lg: 500,
  xl: 1000,
  xxl: 2000,
};

export const FIO_MAX_LENGTH = MAP_OF_TEXT_LENGTH.sx;

export const EMAIL_MAX_LENGTH = MAP_OF_TEXT_LENGTH.md;

export const COMMENT_MAX_LENGTH = 255;

export const ROUTE_NAME_MAX_LENGTH = 125;

export const EXTERNAL_CODE_MAX_LENGTH = 125;

export const TENANT_FULLNAME_MAX_LENGTH = 512;

export const TENANT_FULLNAME_MIN_LENGTH = 5;

export const POWER_OF_ATTORNEY_LENGTH = 100;
