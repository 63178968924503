import { FORM_ERROR_MESSAGE } from '@constants/form/errorText';

import { string } from '../schema/schema';
import { isOGRN } from '../validators/isOGRN';

//TODO: рефакторинг KADRY-5580
export const ogrnFiled = string((value, ctx) => {
  if (!isOGRN(value)) {
    return ctx.createError({
      message: FORM_ERROR_MESSAGE.OGRN,
      code: 'ogrn',
    });
  }

  return undefined;
}).define({ requiredErrorMessage: FORM_ERROR_MESSAGE.REQUIRED });
