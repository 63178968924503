export const getiOSVersion = () => {
  var agent = globalThis.navigator.userAgent;
  var start = agent.indexOf('OS ');

  if (
    (agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) &&
    start > -1
  ) {
    return Number(agent.substr(start + 3, 3).replace('_', '.'));
  }

  return 0;
};
