import { createEvent, createStore } from 'effector';

type HeadStore = {
  subTitles: MetaSubtitle[];
  description: string;
};

type MetaSubtitle = {
  title: string;
  order?: number;
};

export const addMetaSubTitle = createEvent<MetaSubtitle>();

export const removeMetaSubTitle = createEvent<string>();

export const setMetaDescription = createEvent<string>();

export const cleanMetaDescription = createEvent();

const DEFAULT_ORDER = 5;

const checkTitles = (item: MetaSubtitle, title: string) => item.title === title;
const checkStoreTitles = (store: HeadStore, title: string) =>
  store.subTitles.some((storeItem) => checkTitles(storeItem, title));

export const headStore = createStore<HeadStore>({
  subTitles: [],
  description: '',
})
  .on(addMetaSubTitle, (store, item) => {
    if (!checkStoreTitles(store, item.title)) {
      return {
        ...store,
        subTitles: [...store.subTitles, item].sort(
          (a, b) => (a.order ?? DEFAULT_ORDER) - (b.order ?? DEFAULT_ORDER),
        ),
      };
    }

    return store;
  })
  .on(removeMetaSubTitle, (store, title) => {
    if (checkStoreTitles(store, title)) {
      return {
        ...store,
        subTitles: store.subTitles.filter((item) => !checkTitles(item, title)),
      };
    }

    return store;
  })
  .on(setMetaDescription, (store, value) => ({ ...store, description: value }))
  .on(cleanMetaDescription, (store) => ({ ...store, description: '' }));
