import { cloneElement, useEffect, useRef } from 'react';
import { useEscapeClickEffect } from '@hooks/useEscapeClickEffect';

import { ActionButton } from '../../../ActionButton';
import { ApproveIcon, CrossIconMd } from '../../../Icons';
import { Grid } from '../../../Grid';

export type CellInnerProps = {
  onCancel?: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  isDirty: boolean;
  children: JSX.Element;
};

/**
 * общая внутренняя часть для переиспользования в разных реализациях ячеек
 */
export const CellInner = ({
  onCancel,
  isLoading,
  onSubmit,
  children,
  isDirty,
}: CellInnerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEscapeClickEffect({
    isActive: !isLoading,
    onEscape: onCancel,
  });

  return (
    <Grid columns="1fr auto" spacing={2} component="form" onSubmit={onSubmit}>
      {cloneElement(children, { inputRef })}
      <Grid columns={2} spacing={1}>
        <ActionButton
          icon={<ApproveIcon width={20} height={20} fill="currentColor" />}
          type="submit"
          disabled={!isDirty}
          isLoading={isLoading}
          tooltipContent="Подтвердить"
          disableInteractiveInTooltip
        />
        <ActionButton
          icon={<CrossIconMd width={20} height={20} fill="currentColor" />}
          type="button"
          onClick={onCancel}
          disabled={isLoading}
          tooltipContent="Отменить"
          disableInteractiveInTooltip
        />
      </Grid>
    </Grid>
  );
};
