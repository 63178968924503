export const i18n = {
  title: 'Отключение подписания кодом',
  signTitle: 'Подписание документа кодом',
  signDescription:
    'Введите код из приложения генератора одноразового кода. Например: Яндекс Ключ, Google Authenticator или Microsoft Authenticator',
  description:
    'Если у вас нет доступа к приложению — отключите функцию и подключите снова',
  codeDescription: 'Введите код из приложения генератора одноразового кода',
  cancelBtnText: 'Отмена',
  repeatCodeLabel: 'Отправить код повторно',
  smsCodeFieldLabel: (phone: string) =>
    `Отправили смс с кодом на номер ${phone}, введите его ниже`,
};
