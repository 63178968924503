import { InfoOutlinedIcon } from '@common/shared/components';
import { useLocation } from '@common/shared/hooks';
import { forwardRef } from 'react';

import { NavLink } from '../NavLink';

import { type ListItemProps } from './types';
import {
  ListItemIconWrapper,
  ListItemListItemTextWrapper,
  ListItemWrapper,
} from './styles';

export const LinkListItem = forwardRef<HTMLDivElement, ListItemProps>(
  (
    { icon, text, path, disabled, hasErrors = false, replace = false, onClick },
    ref,
  ) => {
    const { pathname } = useLocation();
    const active = pathname.endsWith(path);

    return (
      <NavLink
        path={path}
        disabled={disabled}
        replace={replace}
        onClick={onClick}
      >
        <div ref={ref}>
          <ListItemWrapper selected={active} disabled={disabled}>
            {icon && <ListItemIconWrapper>{icon}</ListItemIconWrapper>}
            {text && (
              <ListItemListItemTextWrapper>{text}</ListItemListItemTextWrapper>
            )}
            {hasErrors && <InfoOutlinedIcon color="error" />}
          </ListItemWrapper>
        </div>
      </NavLink>
    );
  },
);
