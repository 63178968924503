import { Checkbox } from '@common/shared/components';
import { type ReactNode, memo } from 'react';

import { SubmitRegulationsWrapper } from './styles';

type SubmitRegulationsProps = {
  checkboxName: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  content: ReactNode;
  marginTop?: number;
};

//TODO: рефакторинг KADRY-5588
const SubmitRegulations = memo(
  ({
    checkboxName,
    content,
    checked,
    onChange,
    marginTop,
  }: SubmitRegulationsProps) => (
    <SubmitRegulationsWrapper marginTop={marginTop} component="label">
      <Checkbox
        onChange={({ target }) => onChange(target.checked)}
        checked={checked}
        data-test={checkboxName}
      />
      {content}
    </SubmitRegulationsWrapper>
  ),
);

SubmitRegulations.displayName = 'SubmitRegulations';

export { SubmitRegulations };
