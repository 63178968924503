import { Typography, styled } from '@common/shared/components';

export const SubmitRegulationsWrapper = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'mt',
})<{ mt?: number }>`
  cursor: pointer;

  position: sticky;
  bottom: 0;

  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(2)};
  grid-template-columns: auto 1fr;

  margin-top: ${({ theme, mt }) => mt && theme.spacing(mt)};

  background-color: ${({ theme }) => theme.palette.background.element};
`;
