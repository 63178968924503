import {
  type HTMLAttributes,
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  type FieldValues,
  type UseFormSetValue,
  useController,
} from 'react-hook-form';
import { useBackdropStackToggle } from '@astral/ui';
import { observer } from 'mobx-react-lite';
import { classnames } from '@utils/string/classnames';
import { METRIKA_CLASSES } from '@constants/metrikaClasses';
import { type Certificate, cryptoService } from '@services/CryptoService';
import { type FormInputProps } from '@types';

import { Autocomplete, type AutocompleteProps } from '../../Autocomplete';
import { type EmployeeInfoForVerifications } from '../../../types';
import { Spinner } from '../../Spinner';
import { PersonalData } from '../../PersonalData';

import { sortDisabledUQESCertificates, transformCertificate } from './utils';
import { AutocompleteListItem } from './AutocompleteListItem';
import { CertificateDisabledOptions } from './CertificateDisabledOptions';

const CryptoProProvider = lazy(() =>
  import('@astral/features').then((data) => ({
    default: data.CryptoProProvider,
  })),
);

type FormCryptoProCertAutocompleteProps<FormValues extends FieldValues> = {
  infoForVerification?: EmployeeInfoForVerifications;
  returnValueFormat?: (value: Certificate) => unknown;
  setValue?: UseFormSetValue<FormValues>;
} & Omit<
  AutocompleteProps<Certificate, boolean, boolean, false>,
  'options' | 'multiple'
> &
  FormInputProps<FormValues>;

type AutocompleteReturnValue<T> =
  | string
  | T
  | NonNullable<T>
  | (string | T)[]
  | null;

type AutocompleteRenderOptionState = {
  inputValue: string;
  selected: boolean;
};

const CertificatesOptions =
  (infoForVerification?: EmployeeInfoForVerifications) =>
  (
    { className, ...restAttrs }: HTMLAttributes<HTMLLIElement>,
    params: Certificate,
    meta: AutocompleteRenderOptionState,
  ) => {
    const certificate = transformCertificate(params);
    const disabled = CertificateDisabledOptions(params, infoForVerification);

    return (
      <PersonalData>
        <AutocompleteListItem
          {...restAttrs}
          key={certificate.subjectKeyId}
          title={certificate.name}
          sidetitle={certificate.ownerName}
          type={certificate.type}
          checked={meta.selected}
          inn={`ИНН: ${certificate.inn} `}
          notAfter={
            certificate.notAfter ? ` Действует до: ${certificate.notAfter}` : ''
          }
          aria-disabled={disabled}
          disabled={disabled}
        />
      </PersonalData>
    );
  };

export const FormCryptoProCertAutocomplete = observer(
  <FormValues extends FieldValues>({
    returnValueFormat,
    setValue,
    noOptionsText = 'Нет данных',
    infoForVerification,
    ...props
  }: FormCryptoProCertAutocompleteProps<FormValues>) => {
    const cryptoProStore = cryptoService.store;
    const { control, name, loading } = props;
    const isLoading =
      loading || cryptoService.isLoading || !cryptoProStore?.isPluginInstalled;
    const options = sortDisabledUQESCertificates(
      cryptoProStore?.certificateList || [],
      infoForVerification,
    );

    const { handleClose, handleOpen } = useBackdropStackToggle();

    const { field, fieldState } = useController({ control, name });
    const errorMessages = fieldState.error?.message;
    const hasError = Boolean(errorMessages);
    const { onChange, value: innerValue, ref, ...fieldProps } = field;

    const isEmptyValue = (v: unknown) => {
      if (typeof v === 'string') {
        return !!v;
      }

      if (Array.isArray(v)) {
        return !!v.length;
      }

      return false;
    };

    const getDefaultValue = useMemo(() => {
      let isString = false;
      const defaultValue = options?.filter((option) => {
        if (typeof option === 'string') {
          isString = true;

          if (Array.isArray(innerValue)) {
            return innerValue.includes(option);
          }

          return option === innerValue;
        }

        if (typeof option === 'object' && returnValueFormat) {
          if (Array.isArray(innerValue)) {
            return innerValue.includes(returnValueFormat(option));
          }

          return returnValueFormat(option) === innerValue;
        }

        return null;
      });

      return isString
        ? defaultValue?.at(0) || null
        : Object.keys(Object.assign({}, ...defaultValue)).length
          ? Object.assign({}, ...defaultValue)
          : null;
    }, [innerValue, options]);

    const getIsOptionEqualToValue = useCallback(
      (option: Certificate, value: Certificate) => {
        return JSON.stringify(value) === JSON.stringify(option);
      },
      [],
    );

    const handlerChange = useCallback(
      (_: unknown, optionsValue: AutocompleteReturnValue<Certificate>) => {
        const value = optionsValue as Certificate;

        cryptoProStore?.getCertificateBySkid(value?.subjectKeyId as string);

        if (returnValueFormat && value) {
          const returnValue = Array.isArray(value)
            ? value.map(returnValueFormat)
            : returnValueFormat(value);

          return onChange(returnValue);
        }

        if (!isEmptyValue(value)) {
          if (typeof value === 'string') {
            return onChange('');
          }

          if (Array.isArray(value)) {
            return onChange([]);
          }
        }

        return onChange(value);
      },
      [onChange, returnValueFormat],
    );

    useEffect(() => {
      if (
        innerValue &&
        innerValue.length &&
        returnValueFormat &&
        options.length &&
        setValue
      ) {
        const notIncludedValues = Array.isArray(innerValue)
          ? (innerValue.filter(
              (inner: string) =>
                !getDefaultValue.some(
                  (def: Certificate) => returnValueFormat(def) === inner,
                ),
            ) as string[])
          : !getDefaultValue
            ? innerValue
            : [];

        const newInnerValue =
          Array.isArray(innerValue) && notIncludedValues
            ? innerValue.filter(
                (inner: string) =>
                  !notIncludedValues.some(
                    (notIncluded) => notIncluded === inner,
                  ),
              )
            : null;

        if (notIncludedValues.length) {
          onChange(newInnerValue);
        }
      }
    }, [getDefaultValue]);

    return (
      <>
        <Autocomplete
          {...props}
          options={options}
          loading={isLoading}
          {...fieldProps}
          onOpen={handleOpen}
          onClose={handleClose}
          data-test={name}
          className={classnames(
            METRIKA_CLASSES.hideInputValue,
            props.className,
          )}
          loadingText={<Spinner size="xm" type="pale" />}
          noOptionsText={noOptionsText}
          value={getDefaultValue as Certificate}
          isOptionEqualToValue={getIsOptionEqualToValue}
          onChange={handlerChange}
          error={hasError}
          helperText={hasError && errorMessages}
          placeholder={
            getDefaultValue && getDefaultValue.length
              ? undefined
              : props.placeholder
          }
          renderOption={CertificatesOptions(infoForVerification)}
          getOptionLabel={(option) => option.subject.commonName as string}
        />
        {cryptoProStore && (
          <Suspense fallback={null}>
            <CryptoProProvider cryptoProStore={cryptoProStore} />
          </Suspense>
        )}
      </>
    );
  },
);
