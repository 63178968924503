import type { ApiResult } from './ApiResult';

export class ApiError<TData> extends Error {
  public readonly url: string;

  public readonly status: number;

  public readonly statusText: string;

  public readonly body: TData | string | undefined;

  constructor(response: ApiResult<TData>, message: string) {
    super(message);
    this.name = 'ApiError';
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = response.body;
  }
}
