import { CircularProgress, styled } from '@common/shared/components';

export const StateContainer = styled('div')`
  min-width: max-content;
  margin-left: ${({ theme }) => theme.spacing(2)};
  float: right;

  & svg {
    width: 12px;
    height: 12px;
    fill: ${({ theme }) => theme.palette.grey[500]};
  }
`;

export const SpinnerWrapper = styled(CircularProgress)`
  display: block;
  width: 12px !important;
  height: 12px !important;
`;

export const StateInner = styled('div')`
  display: inline-block;
  vertical-align: middle;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
