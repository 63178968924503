import { type RefObject, useEffect } from 'react';
import { type VirtuosoHandle } from 'react-virtuoso';
import { throttle } from '@utils/throttle';
import { useToggle } from '@hooks/useToggle';

import { Fab } from '../../Fab';
import { Tooltip } from '../../Tooltip';
import { UpIcon } from '../../Icons';
import { VIRTUOSO_SCROLLER_CLASSNAME } from '../constants';

import { StickyButtonWrapper } from './styles';
import { stickyButtonI18n } from './constants';

const MIN_SCROLL_POSITION = 100;

export type StickyButtonProps = {
  virtuosoRef: RefObject<VirtuosoHandle>;
  containerRef: RefObject<HTMLDivElement>;
};

export const StickyButton = ({
  containerRef,
  virtuosoRef,
}: StickyButtonProps) => {
  const [isActive, turnOn, turnOff] = useToggle(false);

  useEffect(() => {
    if (containerRef.current) {
      const scrollNode = containerRef.current.querySelector(
        `.${VIRTUOSO_SCROLLER_CLASSNAME}`,
      );

      const onScroll = throttle(() => {
        const scrollTop = scrollNode?.scrollTop ?? 0;

        if (scrollTop > MIN_SCROLL_POSITION) {
          turnOn();
        } else {
          turnOff();
        }
      }, 100);

      scrollNode?.addEventListener('scroll', onScroll);

      return () => scrollNode?.removeEventListener('scroll', onScroll);
    }
  }, []);

  return (
    <StickyButtonWrapper isActive={isActive}>
      <td>
        <Tooltip title={stickyButtonI18n.text}>
          <Fab
            color="primary"
            size="small"
            onClick={() => virtuosoRef.current?.scrollToIndex?.(0)}
          >
            <UpIcon />
          </Fab>
        </Tooltip>
      </td>
    </StickyButtonWrapper>
  );
};
