import { type Theme } from '../../../Theme';
import { styled } from '../../../styled';
import { IconButton } from '../../../IconButton';

import { PAGE_HEADER_ELEMENTS } from './constants';
import { type PageHeaderProps, type PageHeaderStyledProps } from './types';
import { HeaderTitle } from './HeaderTitle';

const getPageHeaderBg = (background: PageHeaderProps['bg'], theme: Theme) => {
  if (background === 'gray') {
    return theme.palette.background.element;
  }

  return theme.palette.background.paper;
};

export const PageHeaderWrapper = styled('header', {
  shouldForwardProp: (prop) =>
    !prop.match(/withSeparator|template|background|rowGap|hasPaddingBottom/),
})<PageHeaderStyledProps>`
  display: grid;
  grid-gap: ${({ theme, rowGap }) => theme.spacing(rowGap || 6)}
    ${({ theme }) => theme.spacing(2)};
  grid-template-areas: ${({ template }) => template.areas};
  grid-template-columns: ${({ template }) => template.columns};
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 2;
  top: 0;

  padding: ${({ theme, withSeparator, hasPaddingBottom }) =>
    theme.spacing(
      6,
      3,
      (withSeparator && 3) || (hasPaddingBottom && 2) || 0,
      3,
    )};

  background-color: ${({ theme, background }) =>
    getPageHeaderBg(background, theme)};
  border-bottom: ${({ theme, withSeparator }) =>
    withSeparator ? '1px solid ' + theme.palette.grey[300] : ''};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme, withSeparator, hasPaddingBottom }) =>
      theme.spacing(
        6,
        6,
        (withSeparator && 6) || (hasPaddingBottom && 2) || 0,
        6,
      )};
  }
`;

export const PageHeaderBackBtn = styled(IconButton)`
  grid-area: ${PAGE_HEADER_ELEMENTS.back};
`;

export const PageHeaderChildrenWrapper = styled.div`
  grid-area: ${PAGE_HEADER_ELEMENTS.children};
`;

export const PageHeaderActionsBelow = styled.div`
  display: flex;
  grid-area: ${PAGE_HEADER_ELEMENTS.actionBelow};
  grid-gap: ${({ theme }) => theme.spacing(2)};
  align-items: flex-start;
`;

export const PageHeaderTitle = styled(HeaderTitle)`
  display: -webkit-box;
  grid-area: ${PAGE_HEADER_ELEMENTS.title};
`;

export const PageHeaderSubtitle = styled.div`
  grid-area: ${PAGE_HEADER_ELEMENTS.subtitle};
`;

export const PageHeaderActionsUnder = styled.div`
  display: grid;
  grid-area: ${PAGE_HEADER_ELEMENTS.actionUnder};
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;

export const PageHeaderWithTagWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PageHeaderTagWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing(4)};
`;

export const PageHeaderactionUnderBelow = styled.div`
  display: flex;
  grid-area: ${PAGE_HEADER_ELEMENTS.actionUnderBelow};
  grid-gap: ${({ theme }) => theme.spacing(2)};
  align-items: flex-start;
  align-self: flex-start;
  justify-content: end;
`;
