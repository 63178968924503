import { FORM_ERROR_MESSAGE } from '@constants/form/errorText';

import { string } from '../schema/schema';
import { isINN } from '../validators/isINN';

//TODO: рефакторинг KADRY-5571
export const innFiled = string((value, ctx) => {
  if (!isINN(value)) {
    return ctx.createError({
      message: FORM_ERROR_MESSAGE.INN,
      code: 'inn',
    });
  }

  return undefined;
}).define({ requiredErrorMessage: FORM_ERROR_MESSAGE.REQUIRED });
