import { type PropsWithChildren } from 'react';

import { StyledNavLink } from './styled';

type NavLinkProps = {
  path: string;
  disabled?: boolean;
  replace?: boolean;
  onClick?: () => void;
};

export const NavLink = ({
  children,
  path,
  disabled = false,
  replace,
  onClick,
}: PropsWithChildren<NavLinkProps>) => (
  <StyledNavLink
    href={path}
    disabled={disabled}
    replace={replace}
    onClick={onClick}
  >
    {children}
  </StyledNavLink>
);
