import { MicroserviceRepository } from '@common/data';
import { TenantManagementClient } from '@api/client/tenantManagement';
import type { GetBaseUrlQuery } from '@common/data/api-core';

import {
  type ApiError,
  type CacheService,
  cacheService,
} from '@dashboard/shared';
import { type DashboardConfig, configRepository } from '@dashboard/data';

import { type TenantsManagementRepositoryDTO } from './dto';
import { tenantLicencesInfoKey } from './constants';

type TenantsManagementRepositoryParams = {
  getConfigQuery: GetBaseUrlQuery<DashboardConfig>;
  cacheService: CacheService<ApiError>;
};

export class TenantsManagementRepository extends MicroserviceRepository<
  TenantManagementClient,
  DashboardConfig
> {
  constructor(private readonly params: TenantsManagementRepositoryParams) {
    super({
      getConfigQuery: params.getConfigQuery,
      microserviceClient: TenantManagementClient,
    });
  }

  public getIncreaseTenantNumberOfLicensesQuery = () =>
    this.params.cacheService.createMutation(
      ({
        tenantId,
        IncreaseBy,
      }: TenantsManagementRepositoryDTO.IncreaseTenantNumberOfLicensesExecutorParams): Promise<unknown> =>
        this.client.tenantLicenses
          .tenantLicensesIncreaseTenantNumberOfLicenses({
            tenantId,
            requestBody: { IncreaseBy },
          })
          .then((r: boolean) => {
            this.params.cacheService.invalidate([
              tenantLicencesInfoKey(tenantId),
            ]);

            return r;
          }),
    );

  public getUpdateLicencesEndedQuery = () =>
    this.params.cacheService.createMutation(
      ({
        tenantId,
        LicencesEnded,
      }: TenantsManagementRepositoryDTO.UpdateLicencesEndedExecutorParams): Promise<unknown> =>
        this.client.tenantLicenses
          .tenantLicensesUpdateLicencesEnded({
            tenantId,
            requestBody: { LicencesEnded },
          })
          .then((r: boolean) => {
            this.params.cacheService.invalidate([
              tenantLicencesInfoKey(tenantId),
            ]);

            return r;
          }),
    );

  public getSetLicencesInfoQuery = () =>
    this.params.cacheService.createMutation(
      (
        params: TenantsManagementRepositoryDTO.SetLicenseInfoExecutorParams,
      ): Promise<unknown> =>
        this.client.tenantLicenses
          .tenantLicensesSetLicenseInfo({
            tenantId: params.tenantId,
            requestBody: {
              LicencesEnded: params.LicencesEnded,
              NumberOfLicenses: params.NumberOfLicenses,
            },
          })
          .then(() => {
            this.params.cacheService.invalidate([
              tenantLicencesInfoKey(params.tenantId),
            ]);
          }),
    );

  public getLicencesInfoQuery = (
    params: TenantsManagementRepositoryDTO.GetLicenseInfoQueryParams,
  ) =>
    this.params.cacheService.createQuery(
      [tenantLicencesInfoKey(params.tenantId)],
      (): Promise<TenantsManagementRepositoryDTO.LicencesInfo> =>
        this.client.tenantLicenses.tenantLicensesGetLicences({
          tenantId: params.tenantId,
        }),
    );
}

export const tenantsManagementRepository = new TenantsManagementRepository({
  getConfigQuery: configRepository.configQuery,
  cacheService,
});
