import { FORM_ERROR_MESSAGE } from '@constants/form/errorText';

import { string } from '../schema/schema';
import { isSNILS } from '../validators/isSNILS';

//TODO: рефакторинг KADRY-5577
export const snilsField = string((value, ctx) => {
  if (value && !isSNILS(value)) {
    return ctx.createError({
      message: FORM_ERROR_MESSAGE.SNILS,
      code: 'incorrect-snils',
    });
  }

  return undefined;
}).define({
  typeErrorMessage: FORM_ERROR_MESSAGE.SNILS,
  requiredErrorMessage: FORM_ERROR_MESSAGE.REQUIRED,
});
