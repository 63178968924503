import { Navigate, Outlet } from 'react-router-dom';
import { lazy } from 'react';

import { type RouteData, appPaths } from '@dashboard/shared';
import { DocumentTemplatesLayout } from '@dashboard/pages/documentTemplates/layout';

const Tenants = lazy(() => import('./tenants/page'));
const TenantCardLayout = lazy(() => import('./tenants/[tenantId]/layout'));
const TenantCardIntegrationTokens = lazy(
  () => import('./tenants/[tenantId]/integrationTokens/page'),
);
const TenantCardData = lazy(() => import('./tenants/[tenantId]/page'));
const TenantCardSettings = lazy(
  () => import('./tenants/[tenantId]/settings/page'),
);
const TenantEmployees = lazy(
  () => import('./tenants/[tenantId]/employees/page'),
);
const Employees = lazy(() => import('./employees/page'));
const DocumentTemplatesTemplates = lazy(
  () => import('./documentTemplates/templates/page'),
);
const DocumentTemplatesHistory = lazy(
  () => import('./documentTemplates/history/page'),
);
const DocumentTemplatesTags = lazy(
  () => import('./documentTemplates/tags/page'),
);
const DocumentTemplatesManual = lazy(
  () => import('./documentTemplates/manual/page'),
);
const UploadSystemTemplates = lazy(
  () => import('./documentTemplates/system/page'),
);
const AuthLogin = lazy(() => import('./auth/page'));

const NotFoundPage = lazy(() => import('@dashboard/pages/NotFound'));

export const routes = (isLoggedIn: boolean): RouteData[] => {
  return [
    {
      path: '/',
      element: <Outlet />,
      children: [
        {
          path: appPaths.auth.base,
          element: <Outlet />,
          children: [
            {
              index: true,
              element: isLoggedIn ? (
                <Navigate to={'/'} replace />
              ) : (
                <AuthLogin />
              ),
            },
          ],
        },
        {
          index: true,
          element: <Navigate to={appPaths.tenants.base} />,
        },
        {
          path: appPaths.employees.base,
          element: <Employees />,
        },
        {
          path: appPaths.tenants.base,
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <Tenants />,
            },
            {
              path: appPaths.tenants.tenantCard(':tenantId'),
              element: (
                <TenantCardLayout>
                  <Outlet />
                </TenantCardLayout>
              ),
              children: [
                {
                  index: true,
                  element: <TenantCardData />,
                },
                {
                  path: appPaths.tenants.tenantSettings(':tenantId'),
                  element: <TenantCardSettings />,
                },
                {
                  path: appPaths.tenants.tenantEmployees(':tenantId'),
                  element: <TenantEmployees />,
                },
                {
                  path: appPaths.tenants.integrationTokens(':tenantId'),
                  element: <TenantCardIntegrationTokens />,
                },
              ],
            },
          ],
        },
        {
          path: appPaths.documentTemplates.base,
          element: (
            <DocumentTemplatesLayout>
              <Outlet />
            </DocumentTemplatesLayout>
          ),
          children: [
            {
              index: true,
              element: <Navigate to={appPaths.documentTemplates.manual} />,
            },
            {
              path: appPaths.documentTemplates.manual,
              element: <DocumentTemplatesManual />,
            },
            {
              path: appPaths.documentTemplates.templates,
              element: <DocumentTemplatesTemplates />,
            },
            {
              path: appPaths.documentTemplates.tags,
              element: <DocumentTemplatesTags />,
            },
            {
              path: appPaths.documentTemplates.history,
              element: <DocumentTemplatesHistory />,
            },
            {
              path: appPaths.documentTemplates.system,
              element: <UploadSystemTemplates />,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ];
};
