import { calcCheckSumForSNILS, removeSpecialCharacters } from '../utils';

const RESTRICTED_VALUES = ['00000000000'];
const DEFAULT_CHECKED_SUM = [0, 100, 101];

/**
 *
 * Функция валидации СНИЛС
 * @param {string} value - Валидируемое значение
 *
 */
export const isSNILS = (value: string) => {
  if (!/^(\d{11})$/.test(removeSpecialCharacters(value))) {
    return false;
  }

  if (RESTRICTED_VALUES.includes(removeSpecialCharacters(value))) {
    return false;
  }

  const checkSum = Number(removeSpecialCharacters(value).slice(9, 11));
  const calculatedCheckSum = calcCheckSumForSNILS(
    removeSpecialCharacters(value),
  );

  if (calculatedCheckSum < DEFAULT_CHECKED_SUM[1]) {
    return calculatedCheckSum === checkSum;
  }

  if (
    calculatedCheckSum === DEFAULT_CHECKED_SUM[1] ||
    calculatedCheckSum === DEFAULT_CHECKED_SUM[2]
  ) {
    return checkSum === DEFAULT_CHECKED_SUM[0];
  }

  if (calculatedCheckSum > DEFAULT_CHECKED_SUM[2]) {
    return (
      calculatedCheckSum % DEFAULT_CHECKED_SUM[2] === checkSum ||
      (calculatedCheckSum % DEFAULT_CHECKED_SUM[2] === DEFAULT_CHECKED_SUM[1] &&
        checkSum === DEFAULT_CHECKED_SUM[0])
    );
  }

  return true;
};
