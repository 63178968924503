import { MoveIcon, Typography, styled } from '@common/shared/components';

export const TimelineDotIcon = styled.div`
  display: flex;
  place-items: center space-between;
`;

export const MoveIconWrapper = styled(MoveIcon)`
  margin-right: 4px;
`;

export const TypographyElement = styled(Typography)``;
