import { makeAutoObservable, when } from 'mobx';
import { type CacheableQuery } from '@services/CacheService';

import { MSECONDS_PER_MINUTE } from '../DateService/constants/msecondsPerMinute';
import { TimeUpdater } from '../TimeUpdater';

const DEFAULT_TIME_TO_UPDATE = MSECONDS_PER_MINUTE;

export type ConfigStoreRequiredData = {
  VERSION: string;
  DISABLE_VERSION_POLLING?: boolean;
};

type ConfigStoreParams<TData> = {
  query: CacheableQuery<TData>;
};

/**
 * стор ответственный за работу с конфигом приложения и обновлением его по времени и по активности пользователя
 * TODO: рефакторинг KADRY-5591
 */
export class ConfigStore<TData extends ConfigStoreRequiredData> {
  private initVersion?: string;

  private isInited = false;

  constructor(private readonly params: ConfigStoreParams<TData>) {
    makeAutoObservable(this);
  }

  public init = (onNewVersion: () => void) => {
    if (this.isInited) {
      return;
    }

    const updater = new TimeUpdater({
      update: this.query.invalidate,
      timeToUpdate: DEFAULT_TIME_TO_UPDATE,
    });

    this.query
      .async()
      .then(({ VERSION, DISABLE_VERSION_POLLING }: ConfigStoreRequiredData) => {
        this.initVersion = VERSION;

        if (DISABLE_VERSION_POLLING) {
          updater.destroy();
        } else {
          when(
            () => this.hasDifferentVersion,
            () => {
              updater.destroy();
              onNewVersion();
            },
          );
        }
      });

    this.isInited = true;
  };

  private get query() {
    return this.params.query;
  }

  private get hasDifferentVersion() {
    if (!this.initVersion || !this.query.data?.VERSION) {
      return false;
    }

    return this.initVersion !== this.query.data?.VERSION;
  }

  public get data(): TData | undefined {
    return this.query.data;
  }

  public get isLoading() {
    return this.query.isLoading;
  }

  public get isSuccess() {
    return Boolean(this.initVersion);
  }

  /**
   * асинхроныый метод получения данных конфига
   */
  public async = () => this.query.async();
}
