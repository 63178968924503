import { type Certificate } from '@astral/features';
import { getFullName } from '@utils/string/getFullname';
import { DateService } from '@services/DateService';
import { type EmployeeInfoForVerifications } from '@types';

const DEPRECATED_ISSUERS = new Set(['4029017981']);

export const CertificateDisabledOptions = (
  initialCert: Certificate,
  initialInfoForVerification: EmployeeInfoForVerifications | undefined,
) => {
  // приводим фио к нижнему регистру, чтобы сделать сравнение нечувствительным к регистру
  const certificate = {
    ...initialCert,
    commonName: initialCert.subject.commonName?.toLowerCase(),
    surname: initialCert.subject.surname?.toLowerCase(),
    name: initialCert.subject.name?.toLowerCase(),
  };

  const infoForVerification = {
    ...initialInfoForVerification,
    FirstName: initialInfoForVerification?.FirstName?.toLowerCase(),
    MiddleName: initialInfoForVerification?.MiddleName?.toLowerCase(),
    LastName: initialInfoForVerification?.LastName?.toLowerCase(),
    Tenant: {
      ...initialInfoForVerification?.Tenant,
      FirstName: initialInfoForVerification?.Tenant?.FirstName?.toLowerCase(),
      MiddleName: initialInfoForVerification?.Tenant?.MiddleName?.toLowerCase(),
      LastName: initialInfoForVerification?.Tenant?.LastName?.toLowerCase(),
    },
  };

  const isCertNotActive =
    DEPRECATED_ISSUERS.has(certificate.issuer.inn ?? '') ||
    (certificate.notAfter &&
      Boolean(
        DateService.differenceInDays(
          new Date(certificate.notAfter),
          Date.now(),
        ) < 0,
      ));

  if (isCertNotActive) {
    return true;
  }

  const regExp = /[^\d]/g;
  const formattedSnils = infoForVerification?.Snils?.replace(regExp, '');

  // если есть ИННЛЕ, то ЮЛ
  if (certificate.subject.innLe) {
    // ЮЛ
    // если не совпадают ИНН и ОГРН сертификата и тената то блочим серт
    return (
      certificate.subject.innLe !== infoForVerification?.Tenant?.InnLe ||
      certificate.subject.ogrn !== infoForVerification?.Tenant.Ogrn
    );
  }

  // Если есть ОГРНИП то ИП
  if (certificate.subject.ogrnip) {
    // ИП
    if (
      !infoForVerification?.Tenant?.FirstName ||
      !infoForVerification?.Tenant.LastName
    ) {
      return true;
    }

    // сверяем ФИО, СНИЛСЫ, ОГРНИПы и ИННы, при несовпадении блочим серт
    return (
      certificate.commonName !==
        getFullName(
          infoForVerification?.Tenant.FirstName,
          infoForVerification?.Tenant?.MiddleName,
          infoForVerification?.Tenant.LastName,
        ) ||
      certificate.surname !== infoForVerification?.LastName ||
      certificate.name !==
        `${infoForVerification?.FirstName} ${infoForVerification?.MiddleName}` ||
      certificate.subject.snils !== formattedSnils ||
      certificate.subject.inn !== infoForVerification?.Tenant.Inn ||
      certificate.subject.inn !== infoForVerification?.Inn ||
      certificate.subject.ogrnip !== infoForVerification?.Tenant.OgrnIp
    );
  }

  // если инн 12ти символьный и нет ОГРНИПа то ФЛ
  if (certificate.subject.inn?.length === 12 && !certificate.subject.ogrnip) {
    // ФЛ
    // Сверяем ФИО, СНИЛСы и ИННы
    return (
      certificate.subject.snils !== formattedSnils ||
      certificate.subject.inn !== infoForVerification?.Inn ||
      certificate.commonName !==
        getFullName(
          infoForVerification?.FirstName,
          infoForVerification?.MiddleName,
          infoForVerification?.LastName,
        )
    );
  }

  return true;
};
