import { observer } from 'mobx-react-lite';

import { OverflowTypography } from '../../../OverflowTypography';
import { ActionButton } from '../../../ActionButton';
import { EditIcon } from '../../../Icons';

import { TextCellWrapper } from './styles';

export type CellTitleAndActionProps = {
  /**
   * текст отображаемый в тултипе кнопки
   */
  actionTooltipText: string;
  text?: string;
  onClick: () => void;
};

/**
 * вспомогательный компонент для отрисовки текста и кнопки переключения
 */
export const CellTitleAndAction = observer(
  ({ text, onClick, actionTooltipText }: CellTitleAndActionProps) => (
    <TextCellWrapper>
      <OverflowTypography>{text}</OverflowTypography>
      <ActionButton
        icon={<EditIcon />}
        tooltipContent={actionTooltipText}
        disableInteractiveInTooltip
        onClick={onClick}
      />
    </TextCellWrapper>
  ),
);
