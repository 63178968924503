import { type AxiosError } from 'axios';
import { HTTP_ERRORS } from '@services/http/ApiError/constants';

type WithRedirectUrl = {
  redirectUrl: string;
};

export const logoutIfUnauthorized = (error: AxiosError) => {
  const { redirectUrl } = (error.response?.data as WithRedirectUrl) || {};
  const isUnauthorized =
    error.response?.status === HTTP_ERRORS.UNAUTHORIZED_HTTP_CODE;

  if (isUnauthorized && redirectUrl) {
    window.location.href = redirectUrl;

    return true;
  }

  return false;
};
