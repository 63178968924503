import { styled } from '@common/shared/components';

export const ListItem = styled('li')`
  & + & {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

export const ArticleLeft = styled('article')`
  display: grid;
  padding: ${({ theme }) => theme.spacing(0, 10, 5, 0)};
  grid-template-areas: 'avatar meta' 'avatar comment';
  grid-column-gap: ${({ theme }) => theme.spacing(3)};
  justify-content: flex-start;
`;

export const ArticleRight = styled(ArticleLeft)`
  padding: ${({ theme }) => theme.spacing(0, 0, 5, 10)};
  justify-content: flex-end;
  grid-template-areas: 'meta avatar' 'comment avatar';

  & header {
    text-align: right;
  }
`;

const Message = styled('main')`
  margin-top: ${({ theme }) => theme.spacing(1)};
  max-width: 430px;
  grid-area: comment;
  position: relative;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.shape.medium};
  padding: ${({ theme }) => theme.spacing(3, 4)};

  & > p {
    display: inline;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: calc(100% - 5px);
    border: 10px solid transparent;
    border-top-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: ${({ theme }) => theme.shape.medium};
  }
`;

export const MessageLeft = styled(Message)`
  &:before {
    border-left-color: ${({ theme }) => theme.palette.grey[100]};
    left: 0;
  }
`;

export const MessageRight = styled(Message)`
  margin-left: auto;
  &:before {
    border-right-color: ${({ theme }) => theme.palette.grey[100]};
    right: 0;
  }
`;

export const AvatarContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  height: calc(100% + 20px);
  grid-area: avatar;
`;

export const MetaContainer = styled('header')`
  grid-area: meta;

  & .MuiTypography-root {
    font-style: normal;
    display: block;
    max-width: calc(100cqw - ${({ theme }) => theme.spacing(16)});
  }
`;
