import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@common/shared/components';

export const ListItemWrapper = styled(ListItem)`
  align-items: center;

  padding: ${({ theme }) => theme.spacing(2)};

  transition: all 0.25s linear;

  svg {
    width: 24px;
    min-width: 24px;
    margin-right: ${({ theme }) => theme.spacing(4)};
  }

  &:hover {
    background: ${({ theme }) => theme.palette.grey[100]};

    .MuiSvgIcon-root {
      fill: currentColor;
    }
  }
`;

export const ListItemIconWrapper = styled(ListItemIcon)`
  margin: 0;
  padding: 0;

  color: currentColor;
`;

export const ListItemListItemTextWrapper = styled(ListItemText)`
  margin: 0;
  padding: 0;
`;

export const LinkListContainer = styled(List)`
  padding: 0;
`;
