import { phoneRegExp } from '../../regexps/phoneRegexp';

export const isPhone = (value: string | undefined, required = true) => {
  if (!value) {
    return !required;
  }

  const replacedPhone = value.replace(/\D+/g, '');

  return phoneRegExp.test(replacedPhone);
};
