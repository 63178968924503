import { backdropStackManager } from '@astral/ui';
import { type SyntheticEvent, useEffect, useRef } from 'react';
import { type AutocompleteCloseReason } from '@components/external';

type Reason = AutocompleteCloseReason | 'escapeKeyDown' | 'backdropClick';

type UseControllableBackdropStackOptions = {
  isOpened: boolean;
  onClose?: (
    _?: SyntheticEvent<Element, Event> | {} | Event,
    reason?: Reason,
  ) => void;
};

export const useControllableBackdropStack = ({
  isOpened,
  onClose,
}: UseControllableBackdropStackOptions) => {
  const { push, remove, generateID } = backdropStackManager;

  const { current: id } = useRef<string>(generateID());

  const handleClose = (
    _?: SyntheticEvent<Element, Event> | {},
    reason?: Reason,
  ) => {
    onClose?.(_, reason);
  };

  useEffect(() => {
    if (isOpened) {
      push(id);
    }

    return () => {
      remove(id);
    };
  }, [isOpened]);

  return { handleClose };
};
