import {
  MAP_OF_TEXT_LENGTH,
  NOT_RESIDENT_PASSPORT_LENGTH,
  PASSPORT_NUMBER_LENGTH,
  PASSPORT_SERIAL_LENGTH,
  PASSPORT_SUBDIVISION_CODE,
} from '@constants/form/fields';
import { FORM_ERROR_MESSAGE } from '@constants/form/errorText';

import { when } from '../schema/schema';

import { createTextField } from './createTextField';
import { createOnlyLettersAndNumbersField } from './createOnlyLettersAndNumbersField';

//TODO: рефакторинг KADRY-5578
export const passportSerialFiled = when({
  is: (_, ctx) =>
    Boolean((ctx.global.values as { IsResident: boolean }).IsResident),
  then: createTextField(true, {
    minLength: PASSPORT_SERIAL_LENGTH,
    maxLength: PASSPORT_SERIAL_LENGTH,
  }),
  otherwise: createTextField(false),
});

export const passportNumberFiled = when({
  is: (_, ctx) =>
    Boolean((ctx.global.values as { IsResident: boolean }).IsResident),
  then: createTextField(true, {
    minLength: PASSPORT_NUMBER_LENGTH,
    maxLength: PASSPORT_NUMBER_LENGTH,
  }),
  otherwise: createOnlyLettersAndNumbersField({
    maxLength: NOT_RESIDENT_PASSPORT_LENGTH,
    maxMessage: FORM_ERROR_MESSAGE.PASSPORT_NUMBER,
    minMessage: FORM_ERROR_MESSAGE.PASSPORT_NUMBER,
    minLength: PASSPORT_NUMBER_LENGTH,
  }),
});

export const passportIssuerSubdivisionCodeField = when({
  is: (_, ctx) =>
    Boolean((ctx.global.values as { IsResident: boolean }).IsResident),
  then: createTextField(true, {
    minLength: PASSPORT_SUBDIVISION_CODE,
    maxLength: MAP_OF_TEXT_LENGTH.sm,
    minMessage: FORM_ERROR_MESSAGE.ISSUER_SUBDIVISION_CODE,
    maxMessage: FORM_ERROR_MESSAGE.ISSUER_SUBDIVISION_CODE,
  }),
  otherwise: createTextField(false),
});
