import {
  ConfigProvider as UIConfigProvider,
  type ConfigProviderProps as UIConfigProviderProps,
} from '@astral/ui';
import { techSupportContacts } from '@constants/techSupportContacts';
import { errorMonitoringService } from '@services/ErrorMonitoringService';
import noDataImgSrc from '@images/error.png';
import outdatedReleaseErrorImgSrc from '@images/refresh_page.png';

import defaultErrorImgSrc from '../Icons/Error.svg';

export type ConfigProviderProps = UIConfigProviderProps;

export const ConfigProvider = (props: ConfigProviderProps) => (
  <UIConfigProvider
    {...props}
    captureException={errorMonitoringService.capture}
    techSup={{
      email: techSupportContacts.email.value,
      phone: techSupportContacts.phone.value,
    }}
    imagesMap={{
      defaultErrorImgSrc,
      noDataImgSrc,
      outdatedReleaseErrorImgSrc,
    }}
  />
);
