import { type Certificate, type CryptoProStore } from '@astral/features';
import { makeAutoObservable, runInAction } from 'mobx';

/**
 * сервис для ленивой загрузки CryptoProStore,
 * позволяет прилично сократить размер бандла
 */
class CryptoService {
  private internalStore: Promise<CryptoProStore> | null = null;

  public store: CryptoProStore | null = null;

  public isLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  public importStore = (): Promise<CryptoProStore> => {
    if (!this.internalStore) {
      this.internalStore = import('@astral/features').then((data) => {
        const store = new data.CryptoProStore();

        runInAction(() => {
          this.store = store;
          this.isLoading = false;
        });

        return store;
      });
    }

    return this.internalStore;
  };
}

const cryptoService = new CryptoService();

export {
  type Certificate,
  type CryptoProStore,
  type CryptoService,
  cryptoService,
};
