import { useEffect } from 'react';

import {
  addMetaSubTitle,
  cleanMetaDescription,
  removeMetaSubTitle,
  setMetaDescription,
} from '../../store';

type UseMetaTitleOptions = {
  title?: string;
  order?: number;
  description?: string;
};

export const useMetaTitle = ({
  title,
  order,
  description = '',
}: UseMetaTitleOptions) =>
  useEffect(() => {
    if (title) {
      addMetaSubTitle({ title, order });
    }

    if (description) {
      setMetaDescription(description);
    }

    return () => {
      if (title) {
        removeMetaSubTitle(title);
      }

      if (description) {
        cleanMetaDescription();
      }
    };
  }, [title, description]);
