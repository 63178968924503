import { memo, useMemo } from 'react';

import { OverflowTypography } from '../../OverflowTypography';
import {
  type MultipleAutocompleteOption,
  type MultipleAutocompleteOptionId,
} from '../types';
import { MultipleAutocompleteTag } from '../MultipleAutocompleteTag';
import { MULTIPLE_AUTOCOMPLETE_TAGS_COUNT } from '../constants';

type MultipleAutocompleteLastTwoTagsProps = {
  options: MultipleAutocompleteOption[];
  selected: MultipleAutocompleteOptionId[];
  onDelete: (item: MultipleAutocompleteOption) => void;
  disabled?: boolean;
  hidePersonalData?: boolean;
};

export const MultipleAutocompleteLastTwoTags = memo(
  ({
    options,
    selected,
    onDelete,
    disabled,
    hidePersonalData,
  }: MultipleAutocompleteLastTwoTagsProps) => {
    const sliced = useMemo(
      () =>
        selected
          .slice(MULTIPLE_AUTOCOMPLETE_TAGS_COUNT * -1)
          .map(
            (id) =>
              options.find((option) => option.id === id) ?? { title: id, id },
          )
          .filter(Boolean),
      [options, selected],
    );

    return (
      <>
        {sliced.map((item, index) => (
          <MultipleAutocompleteTag
            disabled={disabled}
            key={`${item?.id}_${index}`}
            label={
              <OverflowTypography hidePersonalData={hidePersonalData}>
                {item?.title}
              </OverflowTypography>
            }
            onDelete={() => onDelete(item!)}
          />
        ))}
      </>
    );
  },
);
