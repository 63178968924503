import { string } from '@astral/validations';
import { FORM_ERROR_MESSAGE } from '@constants/form/errorText';

import { isGUID } from '../validators/isGUID';

//TODO: рефакторинг KADRY-5579
export const guidField = string((value, ctx) => {
  if (value && !isGUID(value)) {
    return ctx.createError({
      message: FORM_ERROR_MESSAGE.GUID,
      code: 'incorrect-guid',
    });
  }

  return undefined;
}).define({
  typeErrorMessage: FORM_ERROR_MESSAGE.GUID,
  requiredErrorMessage: FORM_ERROR_MESSAGE.REQUIRED,
});
