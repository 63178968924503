import { styled } from '@common/shared/components';

export const ScrollableContainer = styled.div`
  scroll-behavior: smooth;

  overflow-y: scroll;

  max-height: 100%;
  -webkit-overflow-scrolling: touch;
`;
