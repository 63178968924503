import { styled } from '../styled';
import { ArrowIcon } from '../Icons';

type ToggleArrowProps = {
  collapsed: boolean;
};

export const ToggleArrowWrapper = styled(ArrowIcon, {
  shouldForwardProp: (prop) => prop !== 'collapsed',
})<ToggleArrowProps>`
  cursor: pointer;

  transform: rotate(${({ collapsed }) => (collapsed ? '180deg' : '0deg')});

  width: 24px;
  height: 24px;

  transition: all 0.25s linear;
`;
