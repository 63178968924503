import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { type SignatureConfirmationUnregisterStore } from 'common/domain';
import {
  AlertModal,
  CodeField,
  Grid,
  Typography,
} from '@common/shared/components';
import { createPhoneMask, signatureConfirmationTypes } from '@common/shared';

import { i18n } from './constants';
import { TimerButton } from './styles';

type SignatureConfirmationParams = {
  unregisterStore: SignatureConfirmationUnregisterStore;
  title: string;
  description: string;
  signatureConfirmationOnComplete?: (code: string) => void;
  phoneNumber?: string | null;
};

//TODO: рефакторинг KADRY-5589
export const SignatureConfirmationModal = observer(
  function SignatureConfirmation({
    unregisterStore,
    title,
    description,
    signatureConfirmationOnComplete,
    phoneNumber,
  }: SignatureConfirmationParams) {
    const isActive = unregisterStore.isModalActive;
    const ref = useRef<HTMLDivElement>(null);

    const currentTitle =
      unregisterStore.currentType === signatureConfirmationTypes.Sign
        ? title
        : i18n.title;

    useEffect(() => {
      setTimeout(() => {
        if (ref.current) {
          ref.current.querySelector('input')?.focus();
        }
      }, 200);
    }, [ref.current, isActive]);

    return (
      <AlertModal
        open={isActive}
        onClose={unregisterStore.closeModal}
        title={currentTitle}
        maxWidth={false}
        fullWidth={false}
        confirmButtonProps={unregisterStore.confirmButtonProps}
        cancelButtonProps={{
          text: i18n.cancelBtnText,
          onClick: unregisterStore.closeModal,
        }}
        footerTemplate="group"
      >
        {unregisterStore.currentType ===
          signatureConfirmationTypes.Attention && (
          <Typography>{i18n.description}</Typography>
        )}
        {isActive &&
          unregisterStore.currentType === signatureConfirmationTypes.Sign && (
            <CodeField
              ref={ref}
              label={description}
              codeLength={6}
              onComplete={signatureConfirmationOnComplete}
            />
          )}
        {isActive &&
          unregisterStore.currentType === signatureConfirmationTypes.Code && (
            <CodeField
              ref={ref}
              label={i18n.codeDescription}
              codeLength={6}
              onComplete={unregisterStore.unregisterOtpQuery}
            />
          )}
        {isActive &&
          unregisterStore.currentType === signatureConfirmationTypes.Sms && (
            <Grid rowSpacing={10}>
              <CodeField
                ref={ref}
                label={i18n.smsCodeFieldLabel(
                  phoneNumber ? createPhoneMask(phoneNumber) : '',
                )}
                codeLength={6}
                onComplete={unregisterStore.unregisterOtpQuery}
              />
              <Grid
                container
                columns="auto 1fr"
                alignItems="baseline"
                justifyContent="start"
              >
                <TimerButton
                  onClick={unregisterStore.sentSmsQuery}
                  variant="link"
                  disabled={unregisterStore.isRealTimerActive}
                >
                  {i18n.repeatCodeLabel}
                </TimerButton>
                {unregisterStore.isRealTimerActive && (
                  <Typography>
                    {unregisterStore.secondsTimer.textTime}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
      </AlertModal>
    );
  },
);
