import { type ApiError } from '@services/http/ApiError';

export const getApiErrorMessage = (error?: ApiError) => {
  //@ts-ignore TODO: избавиться от игнора, когда окончательно причешем ошибки от бэка KADRY-3248
  const BEMessage = error?.body?.responseException?.exceptionMessage;
  const validationsBEError = // @ts-ignore
    (BEMessage?.errors || error?.body?.responseException?.errors)
      ?.map(({ errorMessage }: { errorMessage: string }) => errorMessage)
      .join('; ');

  return (
    (typeof BEMessage === 'string' && BEMessage) ||
    validationsBEError ||
    error?.message ||
    (error?.body as { message: string })?.message ||
    error?.statusText ||
    'Что-то пошло не по плану'
  );
};
