import { type SkeletonProps as MuiSkeletonProps } from '@mui/material';
import { forwardRef } from 'react';

import { SkeletonWrapper } from './styles';

export type SkeletonProps = MuiSkeletonProps & {
  /**
   * флаг говорящий о том, что загрузка уже не идет, но элемент все еще не доступен
   */
  isUnavailable?: boolean;
  /**
   * будет растягиваться на всю ширину
   * @default false
   */
  isFullWidth?: boolean;
};

export const Skeleton = forwardRef<HTMLElement, SkeletonProps>(
  ({ isUnavailable, ...props }, ref) => (
    <SkeletonWrapper
      ref={ref}
      animation={isUnavailable ? false : 'wave'}
      {...props}
    />
  ),
);
