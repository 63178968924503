import { FORM_ERROR_MESSAGE } from '@constants/form/errorText';
import { EMAIL_MAX_LENGTH } from '@constants/form/fields';

import { email, max, optional, string } from '../schema/schema';

//TODO: рефакторинг KADRY-5567
export const emailField = optional(
  string(
    max(EMAIL_MAX_LENGTH, { getMessage: () => FORM_ERROR_MESSAGE.EMAIL }),
    email(),
  ),
);
