/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetTemplatesOrderBy } from '../models/GetTemplatesOrderBy';
import type { IndexTagTemplateDto } from '../models/IndexTagTemplateDto';
import type { SystemTemplateDto } from '../models/SystemTemplateDto';
import type { SystemTemplateType } from '../models/SystemTemplateType';
import type { TemplateAuditedDto } from '../models/TemplateAuditedDto';
import type { TemplateDto } from '../models/TemplateDto';
import type { TemplateStatusHistoryEntryDto } from '../models/TemplateStatusHistoryEntryDto';
import type { UpdateIndexTagTemplateDto } from '../models/UpdateIndexTagTemplateDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class TemplatesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Получение списка шаблонов
   * @returns TemplateDto Коллекция шаблон
   * @throws ApiError
   */
  public templatesGetTemplates({
    documentTypeId,
    tenantId,
    offset,
    count,
    findText,
    orderBy,
    isDesc,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    documentTypeId?: string | null;
    tenantId?: string | null;
    offset?: number;
    count?: number;
    findText?: string | null;
    orderBy?: GetTemplatesOrderBy | null;
    isDesc?: boolean | null;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<TemplateDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/templates/Templates',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        DocumentTypeId: documentTypeId,
        TenantId: tenantId,
        Offset: offset,
        Count: count,
        FindText: findText,
        OrderBy: orderBy,
        IsDesc: isDesc,
      },
    });
  }

  /**
   * Загрузка нового шаблона
   * @returns string Идентификатор созданного шаблона
   * @throws ApiError
   */
  public templatesAddTemplate({
    tenantId,
    documentTypeId,
    name,
    isActive,
    employeeworkplaceid,
    kedoGatewayTokenType,
    formData,
  }: {
    /** Идентификатор тенанта. **/
    tenantId?: string;
    /** Идентификатор типа документа. **/
    documentTypeId?: string;
    /** Имя. **/
    name?: string;
    /** Признак активности. **/
    isActive?: boolean;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
    formData?: {
      uploadedFile?: Blob | null;
    };
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/templates/Templates',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        tenantId: tenantId,
        documentTypeId: documentTypeId,
        name: name,
        isActive: isActive,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * Получение списка системных шаблонов
   * @returns SystemTemplateDto Коллекция системных шаблон
   * @throws ApiError
   */
  public templatesGetSystemTemplates({
    offset,
    count,
    findText,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    offset?: number;
    count?: number;
    findText?: string | null;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<SystemTemplateDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/templates/Templates/SystemTemplates',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        Offset: offset,
        Count: count,
        FindText: findText,
      },
    });
  }

  /**
   * Получение шаблона
   * @returns TemplateAuditedDto Шаблон
   * @throws ApiError
   */
  public templatesGetTemplate({
    templateId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Идентификатор шаблона. **/
    templateId: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<TemplateAuditedDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/templates/Templates/{templateId}',
      path: {
        templateId: templateId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }

  /**
   * Удаление шаблона
   * @returns boolean Признак успешного завершения
   * @throws ApiError
   */
  public templatesDeleteTemplate({
    templateId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Идентификатор шаблона. **/
    templateId: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v2/templates/Templates/{templateId}',
      path: {
        templateId: templateId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }

  /**
   * Получение истории шаблона
   * @returns TemplateStatusHistoryEntryDto Коллекция событий истории шаблона
   * @throws ApiError
   */
  public templatesGetTemplateHistory({
    templateId,
    offset,
    count = 50,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Идентификатор шаблона. **/
    templateId: string;
    /** Смещение. **/
    offset?: number;
    /** Количество элементов. **/
    count?: number;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<TemplateStatusHistoryEntryDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/templates/Templates/{templateId}/StatusHistory',
      path: {
        templateId: templateId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        offset: offset,
        count: count,
      },
    });
  }

  /**
   * Получение тегов шаблона
   * @returns IndexTagTemplateDto Коллекция тегов
   * @throws ApiError
   */
  public templatesGetTemplateIndexTagsTemplate({
    templateId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Идентификатор шаблона. **/
    templateId: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<IndexTagTemplateDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/templates/Templates/{templateId}/IndexTags',
      path: {
        templateId: templateId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }

  /**
   * Обновление тегов шаблона
   * @returns IndexTagTemplateDto Коллекция тегов
   * @throws ApiError
   */
  public templatesUpdateTemplateIndexTagsTemplate({
    templateId,
    requestBody,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Идентификатор шаблона. **/
    templateId: string;
    /** Коллекция тегов. **/
    requestBody: Array<UpdateIndexTagTemplateDto>;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<IndexTagTemplateDto>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/templates/Templates/{templateId}/IndexTags',
      path: {
        templateId: templateId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Загружаем новый системный шаблон
   * @returns string Идентификатор созданного шаблона
   * @throws ApiError
   */
  public templatesAddSystemTemplate({
    systemTemplateType,
    name,
    isActive,
    employeeworkplaceid,
    kedoGatewayTokenType,
    formData,
  }: {
    /** Тип системного шаблона **/
    systemTemplateType?: SystemTemplateType;
    /** Название шаблона **/
    name?: string;
    /** Признак активности шаблона **/
    isActive?: boolean;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
    formData?: {
      uploadedFile?: Blob | null;
    };
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/templates/Templates/System',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        systemTemplateType: systemTemplateType,
        name: name,
        isActive: isActive,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @deprecated
   * Изменение статуса активности шаблона
   * @returns boolean Признак успешного завершения
   * @throws ApiError
   */
  public templatesUpdateTemplateActivityStatus({
    templateId,
    isActive,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Идентификатор шаблона. **/
    templateId: string;
    /** Признак активности. **/
    isActive?: boolean;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/templates/Templates/{templateId}/ActivationStatus',
      path: {
        templateId: templateId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        isActive: isActive,
      },
    });
  }
}
