import { type ApiError, HTTP_ERRORS } from '@services/http/ApiError';
import { notify } from '@components/Notification';

import { getApiErrorMessage } from '../getApiErrorMessage';

const errorStatusExceptionList = [
  HTTP_ERRORS.UNAUTHORIZED_HTTP_CODE,
  HTTP_ERRORS.UNAVAILABLE_LEGAL_REASONS,
];

export const errorHandler = <T extends ApiError | Error | unknown>(
  error: T,
) => {
  if (
    errorStatusExceptionList.includes((error as ApiError).status) ||
    (error as ApiError).name !== 'ApiError'
  ) {
    return;
  }

  notify.error(getApiErrorMessage(error as ApiError));
};
