import { type Theme } from '@astral/ui';

import { styled } from '../../../styled';

const getFooterBorder = (show: boolean, theme: Theme): undefined | string =>
  show ? `1px solid ${theme.palette.grey[300]}` : undefined;

export const FormFooterWrapper = styled.div<{ showBorder: boolean }>`
  display: flex;
  justify-content: end;

  padding: ${({ theme }) => theme.spacing(4, 0, 4, 0)};

  border-top: ${({ showBorder, theme }) => getFooterBorder(showBorder, theme)};
`;
