import { RouterLink, styled } from '@common/shared/components';

export const StyledNavLink = styled(RouterLink, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>`
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;

  ${({ disabled }) => disabled && 'pointer-events: none;'}
`;
