import { FORM_ERROR_MESSAGE } from '@constants/form/errorText';

import { optional, pattern, string } from '../schema/schema';
import { onlyLettersAndNumberRegExp } from '../regexps';

type CreateOnlyLettersAndNumbersFieldOptions = {
  required?: boolean;
  minLength?: number;
  minMessage?: string;
  maxLength?: number;
  maxMessage?: string;
};

//TODO: рефакторинг KADRY-5575
export const createOnlyLettersAndNumbersField = ({
  required = true,
  ...options
}: CreateOnlyLettersAndNumbersFieldOptions) => {
  const { minLength, minMessage, maxLength, maxMessage } = options;
  const baseTestFiledValidation = string(
    (value, ctx) => {
      if (minLength && minMessage && value.length < minLength) {
        return ctx.createError({
          message: minMessage,
          code: 'length-min',
        });
      }

      if (maxLength && maxMessage && value.length > maxLength) {
        return ctx.createError({
          message: maxMessage,
          code: 'length-max',
        });
      }

      return undefined;
    },
    pattern(onlyLettersAndNumberRegExp, {
      message: FORM_ERROR_MESSAGE.INCORRECT,
    }),
  ).define({ requiredErrorMessage: FORM_ERROR_MESSAGE.REQUIRED });

  return required ? baseTestFiledValidation : optional(baseTestFiledValidation);
};
