import type { AxiosError } from 'axios';

import { HTTP_ERRORS_INFO, INTERNAL_ERROR_INFO } from './constants';
import { createResponseErrorInfo } from './utils';

/**
 * первый тип валидационной ошибки, возвращаемой от бэка
 */
type ApiBEValidationError = {
  responseException: {
    exceptionMessage: string;
    validationErrors: {
      name: string;
      reason: string;
    }[];
  };
};

/**
 * второй альтернативный тип ошибки возвращаемой от бэка
 */
type AltApiBEValidationError = {
  responseException: {
    isValid: false;
    errors: [
      {
        propertyName: string;
        errorMessage: string;
        attemptedValue: string;
        severity: string;
        errorCode: string;
        formattedMessagePlaceholderValues: {
          propertyName: string;
          propertyValue: string;
        };
      },
    ];
    ruleSetsExecuted: string[];
  };
};

/**
 * третий, но не подтвержденный вариант тип ошибки
 */
export type ApiBeError = {
  responseException: {
    exceptionMessage: {
      errors: {
        errorMessage: string;
      }[];
    };
  };
};

export type SimpleError = {
  message: string;
};

type OptimizedBeValidationError = {
  name: string;
  message: string;
};

const isBeValidationError = (body: unknown): body is ApiBEValidationError => {
  if ((body as ApiBEValidationError)?.responseException?.validationErrors) {
    return true;
  }

  return false;
};

const isAltBeValidationError = (
  body: unknown,
): body is AltApiBEValidationError => {
  if ((body as AltApiBEValidationError)?.responseException.errors) {
    return true;
  }

  return false;
};

export type ApiResult = {
  readonly url: string;
  readonly ok: boolean;
  readonly status: number;
  readonly statusText: string;
  readonly body: unknown | ApiBEValidationError;
};

export class ApiError extends Error {
  public readonly url: string;

  public readonly status: number;

  public readonly statusText: string;

  public readonly body:
    | ApiBEValidationError
    | AltApiBEValidationError
    | unknown;

  constructor(response: ApiResult, message: string) {
    super(message);
    this.name = 'ApiError';
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = response.body;
  }

  private static createErrorTemplate(error: AxiosError): {
    result: ReturnType<typeof ApiError.createResponseErrorTemplate>;
    httpErrorInfo: ReturnType<typeof ApiError.getHttpErrorInfo>;
  } {
    const meta = ApiError.makeErrorMeta(
      error.response?.data,
      error.response?.status,
    );

    return {
      result: ApiError.createResponseErrorTemplate(
        meta.info.code,
        error.response?.config.url || '',
        error.response?.data || {},
      ),
      httpErrorInfo: {
        code: meta.info.code,
        message: meta.message,
      },
    };
  }

  static normalize(error: AxiosError) {
    if (error.isAxiosError) {
      const { result, httpErrorInfo } = ApiError.createErrorTemplate(error);

      return Promise.reject(new ApiError(result, httpErrorInfo.message));
    } else {
      const httpErrorInfo = ApiError.getHttpErrorInfo(error.status);
      const result = ApiError.createResponseErrorTemplate(
        httpErrorInfo.code,
        error.response?.config.url || '',
        error.response?.data || {},
      );

      return Promise.reject(new ApiError(result, httpErrorInfo.message));
    }
  }

  static getHttpErrorInfo(status?: number): { code: number; message: string } {
    return (
      HTTP_ERRORS_INFO[status as keyof typeof HTTP_ERRORS_INFO] || {
        code: status,
        message: INTERNAL_ERROR_INFO.message,
      }
    );
  }

  private static createErrorMessageFormList = (
    errorList?: Array<{ errorMessage: string }>,
  ) => {
    if (errorList) {
      return errorList.map(({ errorMessage }) => errorMessage).join(' \n');
    }

    return '';
  };

  public static getBEMessage = (data: unknown): string | undefined => {
    if (
      Array.isArray(
        (data as AltApiBEValidationError)?.responseException?.errors,
      )
    ) {
      return this.createErrorMessageFormList(
        (data as AltApiBEValidationError)?.responseException.errors,
      );
    }

    if (typeof (data as ApiBEValidationError)?.responseException === 'string') {
      return (data as ApiBEValidationError)
        ?.responseException as unknown as string;
    }

    if (
      typeof (data as ApiBEValidationError)?.responseException
        ?.exceptionMessage === 'string'
    ) {
      return (data as ApiBEValidationError)?.responseException
        ?.exceptionMessage;
    }

    if (
      Array.isArray(
        (data as ApiBeError)?.responseException?.exceptionMessage?.errors,
      )
    ) {
      return this.createErrorMessageFormList(
        (data as ApiBeError)?.responseException?.exceptionMessage?.errors,
      );
    }

    if (typeof (data as SimpleError)?.message === 'string') {
      return (data as SimpleError).message;
    }
  };

  public static makeErrorMeta = (data: unknown, status: number | undefined) => {
    const message = this.getBEMessage(data);
    const optimizedStatus = status ?? INTERNAL_ERROR_INFO.code;
    const info = this.getHttpErrorInfo(optimizedStatus);

    return {
      message: message || info.message,
      info,
    };
  };

  public static createResponseErrorTemplate<T extends unknown>(
    status: number,
    url: string,
    body?: T,
  ) {
    return createResponseErrorInfo(url, false, status, `${status}`, body || {});
  }

  public static getValidationErrorText(error?: ApiError, fieldName?: string) {
    if (isBeValidationError(error?.body)) {
      return error?.body?.responseException?.validationErrors?.find(
        ({ name }) => name === fieldName,
      )?.reason;
    }

    if (isAltBeValidationError(error?.body)) {
      return error?.body?.responseException?.errors.find(
        ({ propertyName }) => propertyName === fieldName,
      )?.errorMessage;
    }
  }

  public static getValidationErrors(
    error?: ApiError,
  ): OptimizedBeValidationError[] | undefined {
    if (!error) {
      return;
    }

    if (isBeValidationError(error?.body)) {
      return error?.body?.responseException?.validationErrors?.map(
        ({ name, reason }) => ({ name, message: reason }),
      );
    }

    if (isAltBeValidationError(error?.body)) {
      return error?.body?.responseException?.errors?.map(
        ({ propertyName, errorMessage }) => ({
          name: propertyName,
          message: errorMessage,
        }),
      );
    }
  }
}
