import { forwardRef } from 'react';
import { type NavMenuProps } from '@astral/ui';

import {
  DocumentsIcon,
  PeopleIcon,
  RouterLink,
  type RouterLinkProps,
  SubdivisionsIcon,
  appPaths,
} from '@dashboard/shared';

export const MENU = (pathname: string) => {
  const match = (path: string) => Boolean(pathname.match(path));

  return {
    items: [
      [
        appPaths.tenants.base,
        {
          icon: <SubdivisionsIcon width={22} height={22} fill="currentColor" />,
          text: 'Организации',
          active: match(appPaths.tenants.base),
          component: forwardRef<HTMLAnchorElement, RouterLinkProps>(
            (props, ref) => (
              <RouterLink ref={ref} {...props} href={appPaths.tenants.base} />
            ),
          ),
        },
      ],
      [
        appPaths.employees.base,
        {
          icon: (
            <PeopleIcon
              width={22}
              height={22}
              fill="currentColor"
              viewBox="3 3 19 18"
            />
          ),
          text: 'Сотрудники',
          active: match(appPaths.employees.base),
          component: forwardRef<HTMLAnchorElement, RouterLinkProps>(
            (props, ref) => (
              <RouterLink ref={ref} {...props} href={appPaths.employees.base} />
            ),
          ),
        },
      ],
      [
        appPaths.documentTemplates.base,
        {
          icon: <DocumentsIcon width={22} height={22} fill="currentColor" />,
          text: 'Шаблоны документов',
          active: match(appPaths.documentTemplates.base),
          component: forwardRef<HTMLAnchorElement, RouterLinkProps>(
            (props, ref) => (
              <RouterLink
                ref={ref}
                {...props}
                href={appPaths.documentTemplates.base}
              />
            ),
          ),
        },
      ],
    ] as NavMenuProps['items'],
  };
};
