import { type SignatureConfirmationTypes } from '../types';

export const signatureConfirmationTypes: Record<
  SignatureConfirmationTypes,
  SignatureConfirmationTypes
> = {
  Sign: 'Sign',
  Attention: 'Attention',
  Code: 'Code',
  Sms: 'Sms',
  Notification: 'Notification',
};
