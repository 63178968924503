import { TextField, styled } from '@common/shared/components';

export const Container = styled('section')`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(2, 6)};
  align-items: center;
`;

export const Form = styled('form')`
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  grid-column-gap: ${({ theme }) => theme.spacing(3)};
  align-items: center;
`;

export const Input = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiFormHelperText-root {
    display: none;
  }
`;
